const GmailIcon = () => {
  return (
    <svg width="22" height="23" fill="none">
      <mask
        id="a"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="22"
        height="23"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M21.7 9.4H11.3v4.4h6c-.6 2.8-3 4.4-6 4.4-3.7 0-6.7-3-6.7-6.8 0-3.7 3-6.7 6.7-6.7 1.5 0 3 .6 4.1 1.5l3.3-3.3C16.7 1 14 0 11.3 0 5 0 0 5.1 0 11.4c0 6.3 5 11.4 11.3 11.4 5.6 0 10.7-4.1 10.7-11.4 0-.6-.1-1.4-.3-2z"
          fill="#fff"
        />
      </mask>
      <g mask="url(#a)">
        <path d="M-1 18.2V4.7l8.7 6.7-8.7 6.8z" fill="#FBBC05" />
      </g>
      <mask
        id="b"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="22"
        height="23"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M21.7 9.4H11.3v4.4h6c-.6 2.8-3 4.4-6 4.4-3.7 0-6.7-3-6.7-6.8 0-3.7 3-6.7 6.7-6.7 1.5 0 3 .6 4.1 1.5l3.3-3.3C16.7 1 14 0 11.3 0 5 0 0 5.1 0 11.4c0 6.3 5 11.4 11.3 11.4 5.6 0 10.7-4.1 10.7-11.4 0-.6-.1-1.4-.3-2z"
          fill="#fff"
        />
      </mask>
      <g mask="url(#b)">
        <path d="M-1 4.7l8.7 6.7 3.6-3.1 12.2-2V-1H-1v5.7z" fill="#EA4335" />
      </g>
      <mask
        id="c"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="22"
        height="23"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M21.7 9.4H11.3v4.4h6c-.6 2.8-3 4.4-6 4.4-3.7 0-6.7-3-6.7-6.8 0-3.7 3-6.7 6.7-6.7 1.5 0 3 .6 4.1 1.5l3.3-3.3C16.7 1 14 0 11.3 0 5 0 0 5.1 0 11.4c0 6.3 5 11.4 11.3 11.4 5.6 0 10.7-4.1 10.7-11.4 0-.6-.1-1.4-.3-2z"
          fill="#fff"
        />
      </mask>
      <g mask="url(#c)">
        <path d="M-1 18.2l15.3-12 4 .6L23.6-1v24.9H-1v-5.7z" fill="#34A853" />
      </g>
      <mask
        id="d"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="22"
        height="23"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M21.7 9.4H11.3v4.4h6c-.6 2.8-3 4.4-6 4.4-3.7 0-6.7-3-6.7-6.8 0-3.7 3-6.7 6.7-6.7 1.5 0 3 .6 4.1 1.5l3.3-3.3C16.7 1 14 0 11.3 0 5 0 0 5.1 0 11.4c0 6.3 5 11.4 11.3 11.4 5.6 0 10.7-4.1 10.7-11.4 0-.6-.1-1.4-.3-2z"
          fill="#fff"
        />
      </mask>
      <g mask="url(#d)">
        <path d="M23.5 23.9L7.7 11.4l-2-1.5 17.8-5.2v19.2z" fill="#4285F4" />
      </g>
    </svg>
  );
};

export default GmailIcon;
