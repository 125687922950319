import { Component } from 'react';

export function hideLoader() {
  const loader = document.getElementById('dashboard-loader');
  if (!loader) return;

  setTimeout(() => {
    if (Object.prototype.hasOwnProperty.call(loader, 'remove')) {
      loader.remove();
      return;
    }
    // removeChild() is deprecated, but IE11 does not support .remove() yet. So we use this as a fallback
    if (loader.parentNode) {
      loader.parentNode.removeChild(loader);
    }
  }, 1000);
}

export class LoadingRemover extends Component {
  componentDidMount() {
    hideLoader();
  }

  render() {
    return null;
  }
}
