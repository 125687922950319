function DeliverooIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="124"
      height="33"
      viewBox="0 0 124 33"
      aria-label="Deliveroo"
      focusable="false"
    >
      <path
        fill="#BEC8D4"
        d="M21.2 21.8c-.3.5-1.1.6-2 .2-.5-.3-.5-1-.4-1.4l.3-.6c.2-.2.5-.6.8-.6.7 0 1.2.3 1.5.8.3.5.1 1-.2 1.6zm-6-.7c-.5.4-1 .4-1.7.2-.6-.2-.8-1-.6-1.8.1-.7 1-.8 1.3-.8l.5.1c.3.1.8.4.9.8.1.6 0 1.1-.5 1.5zM21.7 0l-1.6 14.5-2.6-12.2L9.3 4 12 16.3 0 18.8l2.1 9.8 21 4.4L28 22.3 30.2 1 21.8 0zm46.4 12.8c1 0 1.7-.8 1.7-1.7 0-1-.7-1.7-1.7-1.7s-1.7.7-1.7 1.7.8 1.7 1.7 1.7zm-6.5 11.9h2.8V9.6h-2.8v15.1zm45 .2c3 0 5.3-2.2 5.3-5.4 0-3.3-2.3-5.5-5.3-5.5-3.1 0-5.4 2.2-5.4 5.5 0 3.2 2.3 5.4 5.4 5.4zm-19.3 0c1.5 0 3-.4 4.2-1.2l-1-2.3c-1 .5-2.1.8-3.2.8-1.4 0-2.4-.5-2.8-1.6h7.6l.1-1.2c0-3.3-2.3-5.4-5.3-5.4s-5.2 2.2-5.2 5.5 2.2 5.4 5.6 5.4zm36.6-5.4c0-3.3-2.3-5.5-5.3-5.5-3.1 0-5.4 2.2-5.4 5.5 0 3.2 2.3 5.4 5.4 5.4 3 0 5.3-2.2 5.3-5.4zm-68.9 5.4a8 8 0 004.2-1.2l-1-2.3c-1 .5-2 .8-3.2.8-1.4 0-2.4-.6-2.8-1.6h7.6l.1-1.2c0-3.3-2.2-5.4-5.3-5.4-3 0-5.2 2.2-5.2 5.4 0 3.4 2.2 5.5 5.6 5.5zm11.8-.2h2.7V14.2h-2.7v10.5zm7 0h4.3L81 14.2h-3l-2 8.5-2-8.5h-3l2.8 10.5zm13.1-8c1.4 0 2.3.6 2.6 1.8h-5.2c.3-1.2 1.2-1.8 2.6-1.8zm-32.3 0c1.4 0 2.3.6 2.6 1.8h-5.2c.3-1.2 1.2-1.8 2.6-1.8zm64 5.4c-1.6 0-2.8-1-2.8-2.6s1.2-2.6 2.8-2.6c1.6 0 2.7 1 2.7 2.6s-1.2 2.6-2.7 2.6zm-79.2-2.6c0-1.7 1.2-2.7 2.7-2.7 1.6 0 2.8 1 2.8 2.7 0 1.6-1.2 2.6-2.8 2.6-1.5 0-2.7-1-2.7-2.6zm67.2 2.6c-1.6 0-2.8-1-2.8-2.6s1.2-2.6 2.8-2.6c1.6 0 2.7 1 2.7 2.6s-1.1 2.6-2.7 2.6zm-8-5.2c.6 0 1.1.1 1.6.5l1.3-2.9c-.6-.4-1.3-.5-2-.5-1.2 0-2.3.5-2.9 1.6l-.3-1.4h-2.7l.4 2.1v8.4h2.7V18a2 2 0 012-1.1zm-53.4 7.8h2.8l-.5-2.2v-13H45v6c-.8-1-1.9-1.5-3.2-1.5-2.7 0-4.8 2.2-4.8 5.5 0 3.2 2 5.4 4.8 5.4 1.3 0 2.5-.6 3.3-1.5l.3 1.3z"
      />
    </svg>
  );
}

export default DeliverooIcon;
