import { logger } from 'errorTracking';

const isProd = process.env.REACT_APP_DASH_ENV === 'production';
const metricNamebuilder = (name: string): string => `react_dash_${name}`;

interface PrometheusEvent {
  // the namespace which will group all the events
  name: string;
  // extra info to send to pushprom
  help?: string;
  // a set of key value pairs, pleases use values with not too much variance
  labels?: Record<string, string>;
  //
  value?: number;
}

// Counter is a value which will continually rise
interface Counter extends PrometheusEvent {
  // the type of event which will be used in prometheus
  type: 'counter';
  // name of event
  method: 'inc' | 'add';
}

// A gauge is a single value which will go up and down
// eg. temperature
interface Gauge extends PrometheusEvent {
  // the type of event which will be used in prometheus
  type: 'gauge';
  // method to apply on event value
  method: 'set' | 'inc' | 'dec' | 'add' | 'sub';
}
interface Histogram extends PrometheusEvent {
  // the type of event which will be used in prometheus
  type: 'histogram';
  //
  method: 'observe';
  // histogram buckets
  buckets: number[];
}
interface Summary extends PrometheusEvent {
  // the type of event which will be used in prometheus
  type: 'summary';
  // name of event
  method: 'observe';
}

type MeasurementEvent = Counter | Gauge | Histogram | Summary;

const stringifyLabels = (event: MeasurementEvent) => {
  return {
    ...event,
    labels: !event.labels
      ? event.labels
      : Object.fromEntries(
          Object.entries(event.labels).map(([key, value]) => [key, `${value}`]),
        ),
  };
};

export const sendPushPromEvent = async (event: MeasurementEvent) => {
  try {
    if (!isProd) {
      if (event.type !== 'histogram') {
        // eslint-disable-next-line no-console
        console.log(
          '%cPushPromCounter Event',
          'color: #0057ad; padding: 3px; display: block; background: #6BD1FF; font-size: 90%;',
          event,
        );
      }
      return;
    }

    if ('pushpromjs' in window && window.pushpromjs?.sendMetric) {
      window.pushpromjs.sendMetric(stringifyLabels(event));
    } else {
      window.sendEventToPushProm(stringifyLabels(event));
    }
  } catch (e: any) {
    logger.error({
      error: e,
      extras: { category: 'pushpromjs', event },
      tags: { origin: 'pushpromjs' },
    });
  }
};

export const traceCounter = (
  name: PrometheusEvent['name'],
  labels?: PrometheusEvent['labels'],
  method?: Counter['method'],
  value?: Counter['value'],
) =>
  sendPushPromEvent({
    type: 'counter',
    name,
    method: method || 'inc',
    value,
    labels,
  });

export const traceGauge = (
  name: PrometheusEvent['name'],
  labels: PrometheusEvent['labels'],
  method: Gauge['method'] = 'inc',
  value: Gauge['value'],
) => sendPushPromEvent({ type: 'gauge', method, value, name, labels });

// the default buckets in ms
const defaultBuckets = [
  0, 100, 200, 600, 1000, 2000, 3000, 4000, 5000, 6000, 7000, 8000, 9000, 10000,
  11000, 12000, 13000, 14000, 15000, 16000,
];

const getDeviceType = () => {
  if (navigator.userAgent.match(/mobile/i)) {
    return 'Mobile';
  } else if (navigator.userAgent.match(/iPad|Android|Touch/i)) {
    return 'Tablet';
  } else {
    return 'Desktop';
  }
};

export const traceHistogram = ({
  name,
  value,
  labels,
  buckets = defaultBuckets,
}: Partial<Histogram> & {
  name: string;
  value: number;
}) =>
  sendPushPromEvent({
    type: 'histogram',
    method: 'observe',
    name: metricNamebuilder(name),
    buckets,
    value: Math.round(value),
    labels: {
      // @ts-ignore
      connectionType: window?.navigator?.connection?.effectiveType,
      deviceType: getDeviceType(),
      ...labels,
    },
  });
