import { useEffect } from 'react';

import { fetchUserData } from '../helpers/api';
import useLoginSuccessCallback from './useLoginSuccessCallback';

const useAuthenticatedUser = () => {
  const redirectToDashboard = useLoginSuccessCallback();
  useEffect(() => {
    const onUserFetch = async () => {
      try {
        const isAuthenticated = await fetchUserData();
        if (isAuthenticated) {
          redirectToDashboard({ firstLogin: false });
        }
      } catch {
        //Error means user is not logged in.
      }
    };
    if (window.isGuest && window.isGuest !== '1') {
      onUserFetch();
    }
  }, [redirectToDashboard]);
};

export default useAuthenticatedUser;
