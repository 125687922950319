function FacebookIcon() {
  return (
    <svg
      width="86"
      height="40"
      xmlns="http://www.w3.org/2000/svg"
      aria-label="Facebook"
      focusable="false"
    >
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h86v40H0z" />
        <path
          d="M44.3 19.3c-.7 0-1.3.2-1.8.4v5.4h1.3c1.7 0 2-.7 2-1.8v-2.6c0-.8-.4-1.4-1.5-1.4zM33 19c-1.2 0-1.5.6-1.5 1.4v.5h2.9v-.5c0-.8-.3-1.4-1.4-1.4zm-22 5.5c0 .7.3 1 1 1s1.1-.2 1.6-.5v-1.2h-1.5c-.7 0-1 .1-1 .7zm44.3-5.2c-1.2 0-1.6.6-1.6 1.4v3c0 .7.4 1.4 1.6 1.4 1.1 0 1.6-.7 1.6-1.5v-2.9c0-.8-.5-1.4-1.6-1.4zm-50 8.5H1.7v-8.4H0v-2.9h1.8v-1.7c0-2.4 1-3.8 3.7-3.8h2.3v3H6.4c-1 0-1.2.3-1.2 1v1.5H8l-.3 3H5.2v8.3zm11.8 0h-2.9l-.1-.7a6.7 6.7 0 01-3.3.9c-2.1 0-3.2-1.5-3.2-3.4 0-2.3 1.3-3.1 3.6-3.1h2.4V21c0-1.2 0-1.6-2-1.6H8.8l.3-2.9h3.3c4 0 4.8 1.3 4.8 4.5v6.8zm9.9-8.2c-1.8-.3-2.3-.3-3.2-.3-1.6 0-2 .3-2 1.6v2.5c0 1.3.4 1.7 2 1.7.9 0 1.4 0 3.2-.4v2.8c-1.6.4-2.6.5-3.5.5-3.7 0-5.2-2-5.2-4.8v-2c0-2.9 1.5-4.8 5.2-4.8.9 0 2 0 3.5.4v2.8zm10.9 3.6h-6.4v.2c0 1.3.5 1.7 2 1.7 1.4 0 2.3 0 4-.4v2.8c-1.6.4-2.6.5-4.3.5-3.7 0-5.2-2-5.2-4.8v-2.3c0-2.5 1.1-4.5 5-4.5 3.8 0 4.9 2 4.9 4.5v2.3zm11.3 0c0 2.8-.8 4.8-5.5 4.8-1.8 0-2.8-.2-4.7-.5V12l3.5-.6v5.5a8 8 0 012.6-.4c3.5 0 4 1.5 4 4v2.8zm11.1.1c0 2.4-1 4.6-5 4.6-4.1 0-5.1-2.2-5.1-4.6V21c0-2.3 1-4.6 5-4.6 4.2 0 5.1 2.3 5.1 4.6v2.3zm11.2 0c0 2.4-1 4.6-5 4.6-4.2 0-5.2-2.2-5.2-4.6V21c0-2.3 1-4.6 5.1-4.6s5 2.3 5 4.6v2.3zm11.4 4.5h-3.7L76 22.5v5.3h-3.5V12l3.5-.6v10.3l3.2-5h3.7L79.4 22l3.5 5.8zm-16.5-8.5c-1.2 0-1.6.6-1.6 1.4v3c0 .7.4 1.4 1.6 1.4 1.2 0 1.6-.7 1.6-1.5v-2.9c0-.8-.4-1.4-1.6-1.4zM85 25.8c.6 0 1 .5 1 1.1 0 .6-.4 1-1 1a1 1 0 01-1-1c0-.6.4-1 1-1zm0 .2c-.5 0-.9.4-.9 1 0 .4.4.8 1 .8.4 0 .8-.4.8-.9s-.4-.9-.9-.9zm-.2 1.5h-.2v-1.2h.3l.4.1.1.2c0 .2 0 .3-.2.3l.2.3a1 1 0 000 .3h-.1l-.1-.3c0-.1-.1-.2-.3-.2h-.1v.5zm0-.6h.1c.2 0 .3 0 .3-.2 0-.1 0-.2-.3-.2a1 1 0 00-.1 0v.4z"
          fill="#C3CDDD"
          fillRule="nonzero"
        />
      </g>
    </svg>
  );
}

export default FacebookIcon;
