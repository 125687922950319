import { Select, SelectItem } from '@messagebird/plume';
import { MEDIA_QUERIES } from 'helpers/mediaQueries';
import { useMediaQuery } from 'hooks/useMediaQuery';
import styled, { css } from 'styled-components';

const langItems = [
  { label: 'English', value: 'en' },
  { label: 'Bahasa Indonesia', value: 'id' },
  { label: 'Deutsch', value: 'de-de' },
  { label: 'Español', value: 'es-es' },
  { label: 'Français', value: 'fr-fr' },
  { label: 'Nederlands', value: 'nl' },
  { label: 'Portuguese', value: 'pt-br' },
];

export const LanguageSwitcher: React.FC = () => {
  const isMobile = useMediaQuery(`(max-width : ${MEDIA_QUERIES.TABLET})`);
  return (
    <Wrap isMobile={isMobile}>
      <Select
        aria-label="Languages"
        onSelectionChange={val => {
          window.location.href = window.location.href.replace(
            `/${window.appLang}/`,
            `/${val}/`,
          );
        }}
        // this placeholder is never shown unless something is broken, but it's required. Translation is not necessary.
        placeholder="Select your language"
        // fallback so localhost app without backend running doesn't break
        selectedKey={window.appLang || 'en'}
      >
        {langItems.map(({ label, value }) => (
          <SelectItem key={value}>{label}</SelectItem>
        ))}
      </Select>
    </Wrap>
  );
};

const Wrap = styled.div<{ isMobile?: boolean }>`
  min-width: 130px;
  position: relative;

  ${p =>
    p.isMobile &&
    css`
      & {
        width: 100%;
        margin-top: 12px;
      }
    `}
`;
