import { useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Input, Notification, Link } from '@messagebird/plume';
import { Controller, useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import * as yup from 'yup';

import { logger } from '../../errorTracking';
import { login2faUser, resend2fa } from '../../helpers/api';
import { traceCounter } from '../../helpers/metrics';
import useLoginSuccessCallback from '../../hooks/useLoginSuccessCallback';
import { Container, Form } from '../shared/styles';
import { messages } from './domain';

export interface FormData {
  check_code: string;
}

const VerificationCodeForm: React.FC = () => {
  const intl = useIntl();
  const history = useHistory();
  const onSuccessfulLogin = useLoginSuccessCallback();
  const [wasCodeResent, setWasCodeResent] = useState(false);

  const validationSchema = yup.object().shape({
    check_code: yup
      .string()
      .min(
        6,
        intl.formatMessage(messages.validationMin, { field: 'Code', min: 6 }),
      )
      .max(
        6,
        intl.formatMessage(messages.validationMax, { field: 'Code', max: 6 }),
      )
      .matches(/^\d+$/, intl.formatMessage(messages.enterCode))
      .required(intl.formatMessage(messages.fieldRequired)),
  });

  const {
    handleSubmit,
    control,
    setError,
    reset,
    formState: { isValid, isSubmitting, isSubmitted },
  } = useForm<FormData>({
    mode: 'onChange',
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = handleSubmit((formData: FormData) => {
    login2faUser(formData)
      .then(() => {
        traceCounter('login_2fa_auth2_success');
        onSuccessfulLogin({ firstLogin: false });
      })
      .catch(({ response }) => {
        logger.message({
          message: `login_2fa_auth2_errors: ${JSON.stringify(response?.data)}`,
        });
        traceCounter('login_2fa_auth2_errors');
        const error = response?.data?.errors?.[0];
        if (!error) {
          return setError('check_code', {
            message: intl.formatMessage(messages.loginError),
          });
        }
        // redirect to login page if we lost 2fa session
        if (error.key === 'error.2fa') {
          return history.push(`/login`);
        }
        setError('check_code', {
          message: error.description,
        });
      });
  });

  const resendCode = () => {
    resend2fa()
      .then(() => {
        reset({ check_code: '' });
        setWasCodeResent(true);
      })
      .catch(error => {
        setError('check_code', {
          message: intl.formatMessage(messages.loginError),
        });
        logger.message({ message: `Resend error code failed: ${error}` });
      });
  };

  return (
    <Form onSubmit={onSubmit}>
      {wasCodeResent && !isSubmitted && (
        <Notification
          variant="success"
          onClose={() => {
            setWasCodeResent(false);
          }}
          closeText={'Dismiss notification'}
          isClosable
        >
          <FormattedMessage {...messages.twoFactorResent} />
        </Notification>
      )}
      <Container>
        <Controller
          name="check_code"
          control={control}
          rules={{
            required: true,
          }}
          render={({ field, fieldState: { error, isTouched } }) => (
            <Input
              label={intl.formatMessage(messages.code)}
              type="text"
              id="check_code"
              inputMode="numeric"
              autoComplete="one-time-code"
              pattern="[0-9]*"
              placeholder={intl.formatMessage(messages.enterCode)}
              errorMessage={error?.message}
              validationState={
                (isTouched && error?.message) || (isTouched && !field.value)
                  ? 'invalid'
                  : isTouched
                  ? 'valid'
                  : undefined
              }
              autoFocus
              {...field}
            />
          )}
        />
      </Container>
      <span>
        <FormattedMessage
          id="auth.2fa.nocode"
          defaultMessage="Didn't receive the code? <resend>Send it again</resend>"
          values={{
            resend: (msg: string) => (
              <Link
                size="base"
                weight="bold"
                onPress={resendCode}
                data-testid="resend"
              >
                {msg}
              </Link>
            ),
          }}
        />
      </span>
      <Button
        isLoading={isSubmitting}
        variant="primary"
        type="submit"
        isDisabled={!isValid}
        style={{ width: '100%', marginTop: '40px' }}
        data-testid="2fa-login"
      >
        <FormattedMessage {...messages.logIn} />
      </Button>
    </Form>
  );
};

export default VerificationCodeForm;
