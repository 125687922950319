import axios from 'axios';

import { logger } from '../errorTracking';
import { traceCounter } from './metrics';

export function getCsrfToken() {
  return window.yii.getCsrfToken();
}

export function setCsrfToken(token: string, param?: string) {
  window.yii.setCsrfToken(param ?? window.yii.getCsrfParam(), token);
}

export async function refreshCsrfToken() {
  try {
    const { data: token } = await axios.get('/user/token');
    setCsrfToken(token);
  } catch (error: any) {
    if (error?.response) {
      logger.error({
        error: { ...error, message: `CSRF Error: ${error.message}` },
      });
      traceCounter('csrf_refresh_error_auth2', {
        code: `${error?.response?.status}`,
      });
      if (error.response.status === 403) {
        // session expired, refresh page to wipe out memory and render login screen
        window.location.reload();
        return;
      }
    }
  }
}
