export const getInboxUrl = () => {
  switch (process.env.REACT_APP_DASH_ENV) {
    case 'development':
    case 'staging':
      return 'https://inbox-staging.messagebird.com';
    case 'production':
      return 'https://inbox.messagebird.com';
    default:
      throw new Error(
        `No CC hosting url for env ${process.env.REACT_APP_DASH_ENV}`,
      );
  }
};
