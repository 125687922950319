import React, { ReactElement } from 'react';

import { useLocation } from 'react-router-dom';
import styled from 'styled-components';

import { MEDIA_QUERIES } from '../../helpers/mediaQueries';
import LoginWithInbox from '../LoginWithInbox';
import Footer from './Footer';
import Header from './Header';

interface Props {
  children: ReactElement;
}

const Layout: React.FC<Props> = ({ children }) => {
  const location = useLocation();
  const isLogin = location.pathname === '/login';
  return (
    <Container>
      <Header pathname={location.pathname} />
      <Section>{children}</Section>
      {isLogin && <LoginWithInbox />}
      {!window.appBranding?.isWhitelabel && <Footer />}
    </Container>
  );
};

export default Layout;

const Container = styled.section`
  position: fixed; // ignore margin and padding set by browsers
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background: #fff;
  overflow-y: auto;
  @media only screen and (min-width: ${MEDIA_QUERIES.TABLET}) {
    height: 100vh;
  }
`;

const Section = styled.section`
  align-self: center;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  border-radius: var(--plm-dt-size-spacing-2);
  color: var(--plm-dt-color-font-secondary);
  font-size: var(--plm-dt-cta-text-size-regular);
  margin-bottom: var(--plm-dt-size-spacing-10);
  padding: var(--plm-dt-size-spacing-8);
  width: 380px;

  @media only screen and (min-width: ${MEDIA_QUERIES.TABLET}) {
    border: 1px solid var(--plm-dt-color-blue-30);
    padding: var(--plm-dt-size-spacing-16) var(--plm-dt-size-spacing-20);
    margin: var(--plm-dt-size-spacing-32) var(--plm-dt-size-spacing-20)
      var(--plm-dt-size-spacing-10);
    width: 540px;
  }
`;
