import { useEffect } from 'react';

import {
  BrowserClient,
  Hub,
  Severity as SentrySeverity,
  makeMain,
  getCurrentHub,
  defaultIntegrations,
} from '@sentry/browser';
import { traceCounter } from '../helpers/metrics';

export enum AppEnvironment {
  Prod = 'production',
  Staging = 'staging',
  Dev = 'development',
  Test = 'testing',
}

const environment = (process.env.REACT_APP_DASH_ENV ||
  AppEnvironment.Dev) as AppEnvironment;

let authHub: Hub | undefined;
let dashboardHub: Hub | undefined;

export const initializeSentryClient = () => {
  /*
  To ensure we are not creating conflicts with react-dashboard's Sentry instance we create our own client
  rather than using the basic React SDK. See https://docs.sentry.io/platforms/javascript/guides/react/troubleshooting/#using-a-client-directly
  */
  const client = new BrowserClient({
    dsn: 'https://4350f1549a1c4d84bc4f693b5ca79e53@o113111.ingest.sentry.io/5735827', //process.env.SENTRY_DSN,
    environment: environment,
    debug: environment !== AppEnvironment.Prod,
    release: process.env.REACT_APP_RELEASE_NAME || 'unknown',
    enabled: [AppEnvironment.Prod, AppEnvironment.Staging].includes(
      environment,
    ),
    integrations: defaultIntegrations,
  });

  authHub = new Hub(client);

  // Make client the new global hub
  // https://github.com/getsentry/sentry-javascript/issues/1764
  // https://codesandbox.io/s/z3wyllkpq4?file=/src/index.js:210-259
  getCurrentHub().bindClient(client);

  // make our hub the main so we also capture non-explicitly handled errors instead of those bubbling up to react-dashboard project
  // https://github.com/getsentry/sentry-javascript/issues/2606#issuecomment-634900966
  dashboardHub = makeMain(authHub);
};

/*
 * Hook that sets our hub as the main hub on mount (generally it should be already but check just in case)
 * and releases it back to dashboard hub on unmount.
 * project after user visited the page
 */
export const useSentryHub = () => {
  useEffect(() => {
    if (authHub && getCurrentHub() !== authHub) {
      dashboardHub = makeMain(authHub);
    }
    return () => {
      if (dashboardHub) {
        authHub = makeMain(dashboardHub);
      }
    };
  }, []);
};

const getSentryHub = (): Hub => {
  if (!authHub) {
    throw new Error(
      // tag with Dashboard Auth since this would bubble up to dashboard
      `Dashboard Auth: Sentry hub not found. Make sure the client and hub are initialized.`,
    );
  }
  return authHub;
};

type Severity =
  | 'nvm uatal'
  | 'error'
  | 'warning'
  | 'log'
  | 'info'
  | 'debug'
  | 'critical';

type SentryError = {
  error: Error;
  extras?: Record<string, any>;
  tags?: Record<string, string>;
};
function error({ error, extras, tags }: SentryError) {
  if (environment !== AppEnvironment.Test) {
    console.error(error?.message);
  } else {
    traceCounter('sentry_error_auth2', {
      type: 'error',
      message: error?.message,
    });
  }

  new Promise<string>((resolve) => {
    getSentryHub().withScope((scope) => {
      if (extras) scope.setExtras(extras);
      if (tags) scope.setTags(tags);

      const errorId = getSentryHub().captureException(error);
      resolve(errorId);
    });
  });
}

type SentryMessage = {
  message: string;
  level?: Severity;
  extras?: Record<string, any>;
  tags?: Record<string, string>;
};
function message({
  message,
  level = 'error',
  extras,
  tags,
}: SentryMessage): Promise<string> {
  const isLevelInConsole = level in window.console;
  // @ts-ignore
  const typeOfLevel = typeof window.console[level] === 'function';

  const getLevel = isLevelInConsole && typeOfLevel;

  // @ts-ignore
  window.console[getLevel ? level : 'log'](message);

  traceCounter('sentry_error_auth2', {
    type: 'message',
    level: level,
    message: message,
  });

  return new Promise<string>((resolve) => {
    getSentryHub().withScope((scope) => {
      if (extras) scope.setExtras(extras);
      if (tags) scope.setTags(tags);

      const messageId = getSentryHub().captureMessage(
        message,
        SentrySeverity.fromString(level),
      );

      console.warn('[Sentry-Message]', level, message, extras);

      resolve(messageId);
    });
  });
}

export const logger = {
  error,
  message,
};
