import { Button, Notification } from '@messagebird/plume';
import styled, { css } from 'styled-components';

const TitleStyle = css`
  align-self: flex-start;
  font-size: var(--plm-dt-size-text-3-xl);
  line-height: 42px;
  margin: var(--plm-dt-size-spacing-0);
  color: var(--plm-dt-color-gray-100);
  @media only screen and (max-width: 768px) {
    font-size: var(--plm-dt-size-text-2-xl);
  }
`;

const SocialButton = css`
  display: flex;
  align-items: center;
  justify-content: center;
  height: var(--plm-dt-size-spacing-12);
  width: 100%;
  overflow: hidden;
  position: relative;
  text-decoration: none;

  border-radius: 4px;
  border: 1px solid var(--plm-dt-color-blue-30);
  font-size: 14px;
  text-align: left;
  margin-bottom: 8px;
  color: var(--plm-dt-color-blue-60);
  font-weight: 600;

  svg {
    width: var(--plm-dt-size-spacing-6);
    height: var(--plm-dt-size-spacing-6);
    margin: var(--plm-dt-size-spacing-3);
    left: 0;
    top: 0;
    position: absolute;
  }

  &:hover {
    background-color: var(--plm-dt-color-blue-20);
  }
`;

export const TextStyle = css`
  align-self: flex-start;
  font-size: var(--plm-dt-size-text-base);
  color: var(--plm-dt-color-gray-80);
`;

export const Title = styled.h1`
  ${TitleStyle}
  margin-bottom: var(--plm-dt-size-spacing-6);
`;

export const RecoveryTitle = styled.h1`
  ${TitleStyle}
`;

export const SubTitle = styled.span`
  font-size: var(--plm-dt-size-text-base);
  margin-top: var(--plm-dt-size-spacing-2);
  margin-bottom: var(--plm-dt-size-spacing-6);
`;

export const Form = styled.form`
  width: 100%;
`;

export const Line = styled.span`
  display: flex;
  font-size: var(--plm-dt-size-text-xs);
  font-weight: 600;
  letter-spacing: 0.8px;
  color: var(--plm-dt-color-gray-40);
  margin: var(--plm-dt-size-spacing-5) 0;
  width: 100%;
  &:before,
  &:after {
    content: '';
    flex: 1 1;
    border-bottom: 1px solid var(--plm-dt-color-gray-30);
  }
  &:before {
    margin: auto var(--plm-dt-size-spacing-3) auto 0px;
  }
  &:after {
    margin: auto 0 auto var(--plm-dt-size-spacing-3);
  }
`;

export const SSOLoginButton = styled.a`
  ${SocialButton}
  margin-bottom: var(--plm-dt-size-spacing-2);
`;

export const FacebookSocialButton = styled.a`
  ${SocialButton}
  margin-bottom: var(--plm-dt-size-spacing-2);
`;

export const GoogleSocialButton = styled.a`
  ${SocialButton}
  margin-bottom: var(--plm-dt-size-spacing-2);
`;

export const ActionRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Container = styled.div<{
  hasNotification?: boolean;
  isCheckbox?: boolean;
}>`
  margin-bottom: ${({ isCheckbox }) =>
    isCheckbox
      ? `var(--plm-dt-size-spacing-10)`
      : `var(--plm-dt-size-spacing-6)`};
  ${({ hasNotification }) => hasNotification && `margin-top: 10px`};

  .plm-c-checkbox__checkmark {
    flex-shrink: 0;
  }
`;

export const Box = styled.div`
  width: 100%;
`;

export const FullWidthButton = styled(Button)`
  width: 100%;
`;

export const Text = styled.p`
  ${TextStyle}
  margin-bottom: var(--plm-dt-size-spacing-10);
`;

export const StyledNotification = styled(Notification)`
  margin-bottom: var(--plm-dt-size-spacing-3);
  font-size: var(--plm-dt-size-text-sm);
`;
