export type SignupUser = {
  email: string;
  password: string | undefined;
  signup_source_id: string | undefined;
  target: any | undefined;
  coupon: string | undefined;
  'g-recaptcha-response': string;
  agreed: boolean;
  /** Social Media Sign Up */
  oauth_id: string | undefined;
  oauth_provider: 'google' | 'facebook' | undefined;
  oauth_token: string | undefined;
  given_name: string | undefined;
  family_name: string | undefined;
};

export interface FormData extends SignupUser {
  serverError: string;
}

export type SignUpUserResponse = {
  requires_email_validation: boolean;
  token: string;
  profile_id: string;
  workspace_id: number;
  // onboardingChoice: OnboardingPayload;
};

export type Funnels =
  | 'wa-inbox'
  | 'inbox'
  | 'api'
  | 'wa-api'
  | 'channel'
  | 'fallback'
  | 'generic';

export const authProject = 'dashboard-auth';

export enum AuthEvents {
  Default = 'product_onboardingwizard',
}

export enum targetTrackingMap {
  inbox = 'channel',
  api = 'api',
  'wa-api' = 'wa-api',
  'wa-inbox' = 'inbox',
  channel = 'channel',
  fallback = 'fallback',
  generic = 'generic',
}

export enum Analytics {
  CREATE_ACC_SUCCESS = 'acc_created_success_fe',
  CREATE_ACC_FAILURE = 'acc_created_failure_fe',
  SIGNUP = 'signup',
  CREATE_PASSWORD = 'login:create-password',
  FORGOT_PASSWORD = 'login:forgot-password',
  LOGIN_2FA = 'login:2fa',
  LOGIN_FORM = 'login:form',
}

export interface UserResponsePayload {
  isSubaccount: boolean;
  isSubaccountWithBalance: boolean;
  parentWorkspaceName: string;
  hasSubaccounts: boolean;
  billingType: string;
  isBalanceAccount: boolean;
  hasSentMessages: boolean;
  hasSubscriptions: boolean;
  twoFactorEnabled: null | string;
  hasMadeVoiceCalls: boolean;
  isOwnerOfCurrentWorkspace: boolean;
  isImpersonating: boolean;
  impersonatorEmail: string;
  canUseVoiceCalling: boolean;
  hasSmppAccounts: boolean;
  canReceiveFreeCredits: boolean;
  isPostPaid: boolean;
  workspaceId: number;
  mtId: number;
  workspaceName: string;
  roles: string[];
  treatments: string[];
  createdAt: Date;
  updatedAt: Date;
  legacyWhiteLabel: boolean;
  isWhiteLabel: boolean;
  shortcodeEnabled: boolean;
  isChatApiUser: boolean;
  isInvitedProfile: boolean;
  approvalRequestStatus: number;
  statuses: {
    profile: 'active' | string;
    workspace: 'active' | string;
  };
  ipBasedCountry: string;
  emailVerified: boolean;
  contactCenterEnabled: boolean;
}
