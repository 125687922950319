import { MessageBirdLogo } from './MessageBirdLogo';

const Logo = () => {
  return (
    <a href="/" aria-label="Home">
      {window?.appBranding?.isWhitelabel ? (
        <img
          src={window?.appBranding?.logo}
          height="24"
          alt="Logo"
          role="presentation"
        />
      ) : (
        <MessageBirdLogo />
      )}
    </a>
  );
};

export default Logo;
