function AbnIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100"
      height="36"
      viewBox="0 0 100 36"
      aria-label="Abn amro"
      focusable="false"
    >
      <g fill="none" fillRule="evenodd">
        <path
          fill="#BEC8D4"
          d="M98.2 12.6c.5.2 1 .6 1.2 1 .6 1.6.5 3.6.5 5.4 0 .8-.2 2.5-1.2 3.3-.7.5-1.6.6-2.5.6-1.2 0-2.5-.5-3-1.7-.5-1.3-.4-3.2-.4-4.9 0-1.2.2-2.7 1.3-3.5 1-.7 3-.7 4.1-.2zm-49-.3l2.8 7.3v-7.3h2v10.4h-2.8l-3-7.7v7.7h-2V12.3h3zm24.6 0l1.7 7.3 1.8-7.3h3.3v10.4h-2v-8.2l-2.1 8.2h-2l-2-8.1v8h-2.1V12.4h3.4zm14 0c1 .1 1.7.6 2 1.4.3 1 .3 2.4-.4 3.2-.3.4-.8.6-1.4.7l.5.1c.7.1 1 .5 1.3 1 .3 1 0 2.2.3 3.3l.1.7h-2.1c-.4-1.2 0-2.7-.4-3.8-.3-.6-1.3-.5-2-.5v4.3h-2V12.3h4.2zm-56.7 0l2.8 10.4h-2.2l-.6-2.3h-3l-.6 2.3h-2.1l2.8-10.4h2.9zm10.3.2c.7 0 1.4.7 1.5 1.4.3.9.3 2-.4 2.7-.3.4-.7.5-1.1.7.3 0 .6.2 1 .4.9.6 1 2 .8 3-.2.9-1 1.7-1.7 1.9a5 5 0 01-1.1 0h-4.1V12.4c1.5 0 4 0 5.1.2zm23.9-.2L68 22.7h-2.2l-.6-2.3h-3l-.6 2.3h-2.1l2.8-10.4h2.9zm31 1.4c-.5 0-1.2.2-1.3 2v3.6c.1 1.8.6 2.1 1.4 2.1.8 0 1.3-.4 1.3-2.3v-3.4c0-2-1-2-1.4-2zm-57.9 4.4v3c.9 0 1.7.2 2.3-.3.6-.4.5-1.4.3-2-.4-.9-1.7-.7-2.6-.7zm-8.8-3.9l-1 4.6h2l-1-4.4v-.2zm34.2 0l-1 4.6h2l-1-4.4v-.2zm-6.5 1.1l1.3 1.4-1.3 1.3-1.3-1.3 1.3-1.4zM85.7 14v3s1.4.1 1.9-.5c.3-.5.3-1.4.1-2-.4-.7-1.3-.5-2-.5zm-47.3 0v2.6H40c.7 0 1-.4 1.1-.8.1-.5 0-1.1-.4-1.5l-.2-.2c-.3-.2-1.3-.1-2-.1z"
        />
        <path fill="#E2EBF6" d="M18.9 25.8l-9.4 9.6-.1-.5V16h.6l8.8 9.4z" />
        <path fill="#BEC8D4" d="M9.5 35.4L0 25.8V10h18.9v15.8l-9.4-9.4z" />
      </g>
    </svg>
  );
}

export default AbnIcon;
