/* eslint-disable */
// @ts-nocheck
import React, {
  ComponentType,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { createStore } from './store';

const API_URL = 'https://dotcom.messagebird.com/events';

const currentPageId = () => {
  // In case this page is getting rendered with SSR
  if (typeof window !== 'object') {
    return '#';
  }

  return document.location.pathname + document.location.search;
};

const useStore = createStore({
  country: 'unknown',
  visitorId: 'unknown',
  sessionId: Math.random().toString(36).slice(2, 14),
  pageId: undefined,
  firstPageLoadedAt: Date.now(),
  enabled: true,
  loading: true,
});

export type ITrack = (action: string, event: any) => void;

type InstrumentationContextType = {
  country: string;
  visitorId: string;
  sessionId: string;
  track: ITrack;
};

const InstrumentationContext = createContext<InstrumentationContextType | null>(
  null,
);

export const useInstrumentationContext = () =>
  useContext(InstrumentationContext);

function loadScript(url) {
  return new Promise((resolve, reject) => {
    const el = document.createElement('script');
    el.onload = resolve;
    el.onerror = reject;
    el.src = url;
    document.documentElement.appendChild(el);
  });
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare const FingerprintJS: any;

const Fingerprint: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const [store, setStore] = useStore();

  useMemo(() => {
    const initScript = async () => {
      if (!store.loading || !store.enabled || !document) {
        return;
      }

      const fpjs = await loadScript(
        'https://dotcom.messagebird.com/assets/fp.min.js',
      );

      try {
        const loaded = await FingerprintJS.load(fpjs);
        const result = await loaded.get();

        setStore({
          visitorId: result.visitorId,
          country: result.country,
          loading: false,
        });
      } catch (e) {
        console.error('Failed', e);
        setStore({
          loading: false,
        });
      }
    };

    return initScript();
  }, [store.loading, store.enabled]);

  return <>{children}</>;
};

const BaseInstrumentation: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const [store, setStore] = useStore();
  const [utmTags, setUtmTags] = useState({});
  const { track } = useContext(InstrumentationContext);

  const handleUnload = () => {
    const timeSpent = Date.now() - store.firstPageLoadedAt;
    track('pageUnload', { timeSpent });
  };

  // Detect UTM tags
  useEffect(() => {
    if (!window) {
      return;
    }

    const url = window.location.search;
    const urlParts = url.split('?');
    if (urlParts.length < 2) {
      return;
    }

    const params = urlParts[1].split('&');
    const utm = {};
    for (let i = 0; i < params.length; i++) {
      const keyValue = params[i].split('=');
      const key = keyValue[0];
      const value = keyValue[1];
      if (key.startsWith('utm_')) {
        const camelCaseKey = key
          .substring(4)
          .replace(/-/g, '_')
          .replace(/\b[a-z]/g, function (letter) {
            return letter.toUpperCase();
          });
        utm[`utm${camelCaseKey}`] = value;
      }
    }

    setUtmTags(utm);
  }, []);

  const [pageId, setPageId] = useState<string>(currentPageId());

  useEffect(() => {
    if (!utmTags || !Object.keys(utmTags).length) {
      return;
    }

    // Track campaign
    track('campaign', utmTags);
  }, [utmTags]);

  useEffect(() => {
    const from = store.pageId;
    const to = pageId;

    if (!store.pageId) {
      track('pageLoad', {
        to,
        ...utmTags,
      });
    } else {
      track('pageChange', {
        from,
        to,
      });
    }

    setStore({
      pageId,
    });
  }, [pageId, utmTags]);

  useEffect(() => {
    if (!window) {
      return;
    }

    window.addEventListener('beforeunload', handleUnload);
    return () => window.removeEventListener('beforeunload', handleUnload);
  }, [handleUnload]);

  return <>{children}</>;
};

export function withInstrumentationContext(Component): ComponentType {
  return (props) => {
    const [store, setStore] = useStore();
    const [queued, setQueued] = useState([]);

    const track = useCallback(
      (action: string, event: any) => {
        if (store.visitorId === 'unknown' || !store.enabled) {
          // Still loading fingerprint or not enabled (ie not given consent) etc. Queue events for it to be the flushed
          // when the HOC is ready.
          queued.push({ action, event });
          return;
        }

        fetch(API_URL, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json; charset=utf-8',
          },
          body: JSON.stringify({
            call: 'track',
            data: {
              visitorId: store.visitorId,
              sessionId: store.sessionId,
              pageId: store.pageId,
              activity: action,
              payload: event,
            },
          }),
        }).then((res) => res.text());
      },
      [store.visitorId, store.sessionId, store.loading, store.enabled, queued],
    );

    // Flush queued
    useEffect(() => {
      if (queued.length && store.visitorId !== 'unknown') {
        queued.forEach((item) => {
          track(item.action, item.event);
        });
        setQueued([]);
      }
    }, [queued, store.visitorId]);

    return (
      <Fingerprint>
        <InstrumentationContext.Provider
          value={{
            country: store.country,
            visitorId: store.visitorId,
            sessionId: store.sessionId,
            track,
          }}
        >
          <BaseInstrumentation>
            <div id="provider Wrappers">
              <Component {...props} />
            </div>
          </BaseInstrumentation>
        </InstrumentationContext.Provider>
      </Fingerprint>
    );
  };
}

export function withInstrumentation(Component): ComponentType {
  return (props) => {
    const [store, setStore] = useStore();
    const context = useContext(InstrumentationContext);
    if (!context) {
      console.error('InstrumentationContext was used outside of its Provider');
    }

    if (store.loading) {
      return <></>;
    }

    return (
      <Component
        {...props}
        track={(context && context.track) || function () {}}
        country={(context && context.country) || 'unknown'}
        visitorId={(context && context.visitorId) || 'unknown'}
      />
    );
  };
}
