import styled, { keyframes } from 'styled-components';

const fadeIn = keyframes`
  0% {
    -webkit-transform: translateY(40px);
    transform: translateY(40px));
    opacity: 0;
  }

  100% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
    opacity: 1;
  }
`;

export const FadeAnimation = styled.article`
  display: block;
  width: 100%;
  height: 100%;
  animation: 0.3s ${fadeIn} ease;
`;
