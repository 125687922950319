import React from 'react';

function UberIcon() {
  return (
    <svg
      width="55"
      height="20"
      xmlns="http://www.w3.org/2000/svg"
      aria-label="Uber"
      focusable="false"
    >
      <path
        fill="#BEC8D4"
        d="M2.9 0v12.1c0 .8 0 1.5.3 2.2a5 5 0 001 1.6c.4.5 1 .9 1.5 1a4.7 4.7 0 005.2-1c.4-.4.8-1 1-1.6a6 6 0 00.4-2.2V0H15v19.7h-2.8v-1.9a6.6 6.6 0 01-5 2.2c-1 0-2-.2-3-.5a7.4 7.4 0 01-3.8-4c-.3-1-.5-2-.5-3.2V0h2.9zm16.5 0v7.2c.7-.7 1.4-1.2 2.3-1.6.8-.4 1.7-.6 2.7-.6a7.1 7.1 0 015.2 2.2 7.5 7.5 0 011.6 8.2 7.6 7.6 0 01-3.9 4 7.1 7.1 0 01-7.9-1.5v1.8h-2.8V0h2.8zm21 5.3a7 7 0 015 2A7 7 0 0147 9.8c.4.9.5 1.9.5 3v.8H36.1a4.8 4.8 0 004.6 4c1.7 0 3-.6 4-2l2 1.5c-.7 1-1.5 1.7-2.5 2.2-1 .5-2.2.8-3.5.8a7.6 7.6 0 01-5.3-2.1 7.4 7.4 0 01-2.1-5.3 7.4 7.4 0 014.3-6.8 7 7 0 012.9-.5zm14.6 0V8h-1c-.8 0-1.4.3-2 1-.4.6-.7 1.5-.7 2.7V20H49V5.4h2.4v1.8c.3-.6.7-1 1.2-1.4.4-.4 1-.5 1.7-.5h.8zM24.2 7.6A4.7 4.7 0 0020.8 9a5 5 0 00-1.4 3.5 5 5 0 001.4 3.5c.4.5 1 .8 1.5 1.1s1.2.4 2 .4a4.8 4.8 0 003.4-1.5 5.2 5.2 0 001.3-3.5A5 5 0 0027.6 9a4.6 4.6 0 00-3.4-1.4zm16.2.1c-1 0-2 .3-2.7 1-.8.6-1.3 1.5-1.6 2.6h8.5c-.2-1-.7-2-1.5-2.6a4 4 0 00-2.7-1z"
      />
    </svg>
  );
}

export default UberIcon;
