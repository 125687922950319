import { Link } from '@messagebird/plume';
import { FormattedMessage } from 'react-intl';
import { Link as RouterLink } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { LanguageSwitcher } from './LanguageSwitcher';

const HeaderMessage: React.FC<{ pathname: string; isMobile?: boolean }> = ({
  pathname,
  isMobile,
}) => {
  if (pathname?.includes('login') || pathname?.includes('forgot-password')) {
    return !window?.appBranding?.isWhitelabel ? (
      <Flex isMobile={isMobile}>
        <LanguageSwitcher />
      </Flex>
    ) : null;
  } else if (pathname?.includes('sign-up') || pathname?.includes('signup')) {
    return (
      <Flex isMobile={isMobile}>
        <LanguageSwitcher />
        <Separator isMobile={isMobile}>|</Separator>
        <FormattedMessage
          tagName="span"
          id="auth.signup.alreadyhaveaccount"
          defaultMessage="Already have an Account? <a>Log in here</a>"
          values={{
            a: (msg: string) => (
              <Link
                as={RouterLink}
                size="sm"
                weight="bold"
                to="/login"
                style={{ marginLeft: '2px' }}
              >
                {msg}
              </Link>
            ),
          }}
        />
      </Flex>
    );
  } else {
    return null;
  }
};

export default HeaderMessage;

const Flex = styled.div<{ isMobile?: boolean }>`
  display: flex;
  align-items: center;

  ${p =>
    p.isMobile &&
    css`
      & {
        flex-direction: column-reverse;
        margin-top: var(--plm-dt-size-spacing-24);
      }
    `}
`;

const Separator = styled.span<{ isMobile?: boolean }>`
  margin-left: 24px;
  margin-right: 24px;
  font-size: 20px;
  color: #c3cddd;
  width: 0;

  ${p =>
    p.isMobile &&
    css`
      & {
        display: none !important;
      }
    `}
`;
