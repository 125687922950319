import deMessages from '../translations/de.json';
import enMessages from '../translations/en.json';
import esMessages from '../translations/es.json';
import frMessages from '../translations/fr.json';
import idMessages from '../translations/id.json';
import nlMessages from '../translations/nl.json';
import ptMessages from '../translations/pt_BR.json';

export type Languages = 'en' | 'fr' | 'de' | 'nl' | 'es' | 'id' | 'pt';

enum SUPPORTED_LANGUAGES {
  en = 'en',
  fr = 'fr',
  de = 'de',
  nl = 'nl',
  es = 'es',
  id = 'id',
  pt = 'pt',
}

export const langToMessages: Record<Languages, Record<string, string>> = {
  en: enMessages,
  nl: nlMessages,
  fr: frMessages,
  de: deMessages,
  es: esMessages,
  id: idMessages,
  pt: ptMessages,
};

// Type Guard to ensure passed in language is a valid AppLanguage
export const isAppLanguage = (language: string): language is Languages => {
  return language in SUPPORTED_LANGUAGES;
};

// Read out language for translations from window.appLang (split in case of e.g. `en-US`) and fall back to english if no valid language found
export const getAppLanguage = (lang = window.appLang || 'en') => {
  const splitLang = lang.split('-')[0];
  return isAppLanguage(splitLang) ? splitLang : lang;
};
