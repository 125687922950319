import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import { RecoveryTitle as Title, TextStyle } from '../../shared/styles';

const CheckEmail: React.FC = () => (
  <>
    <Title>
      <FormattedMessage
        id="auth.forget-password.thanks.title"
        defaultMessage="Thanks, check your email!"
      />
    </Title>
    <Text>
      <FormattedMessage
        id="auth.forget-password.thanks.subtitle"
        defaultMessage="We just sent you a link to reset your password."
      />
    </Text>
  </>
);
export default CheckEmail;

const Text = styled.p`
  ${TextStyle}
  margin-bottom: var(--plm-dt-size-spacing-2);
`;
