import { useCallback, useEffect } from 'react';

import { useMediaQuery } from '../hooks/useMediaQuery';
import { MEDIA_QUERIES } from './mediaQueries';
import { AuthEvents, authProject, Funnels, targetTrackingMap } from './types';

let impersonatorEmail = '';

export function track(id: string, data?: any) {
  // If we we are on development, we don't actually want to track with an APIs call. Instead, log to the console.
  if (process.env.REACT_APP_DASH_ENV === 'development') {
    // eslint-disable-next-line
    console.log(
      '%cAnalytics Track [AUTH]', // jshint ignore:line
      'color: #0a0; padding: 3px; display: block; background: #beb; font-size: 90%;',
      id,
      {
        ...(impersonatorEmail ? { impersonator_email: impersonatorEmail } : {}),
        ...data,
      },
    );

    return;
  }

  if (window?.analytics) {
    window?.analytics.track(id, {
      ...(impersonatorEmail ? { impersonator_email: impersonatorEmail } : {}),
      ...data,
    });
  }
}

export function identify(data: unknown, properties = {}) {
  //@ts-ignore
  const { impersonatorEmail: email, ...otherProps } = properties;
  if (process.env.REACT_APP_DASH_ENV === 'development') {
    // eslint-disable-next-line
    console.log(
      '%cAnalytics Identify [AUTH]', // jshint ignore:line
      'color: #0a0; padding: 3px; display: block; background: #beb; font-size: 90%;',
      data,
      properties,
      email,
    );

    return;
  }

  impersonatorEmail = email;

  if (window.analytics) {
    window.analytics.identify(data, otherProps);
  }
}

export function page(id: string, data: any) {
  const segmentData = {
    ...data,
  };

  // If we we are on development, we don't actually want to track with an APIs call. Instead, log to the console.
  if (process.env.REACT_APP_DASH_ENV === 'development') {
    // eslint-disable-next-line
    console.log(
      '%cAnalytics Page [AUTH]', // jshint ignore:line
      'color: #0a0; padding: 3px; display: block; background: #beb; font-size: 90%;',
      id,
      segmentData,
    );

    return;
  }

  if (window.analytics) {
    window.analytics.page(id, segmentData);
  }
}

export const useTrack = () => {
  const isMobile = useMediaQuery(`(max-width : ${MEDIA_QUERIES.TABLET})`);
  const deviceType = isMobile ? 'mobile' : 'web';
  return useCallback(
    ({
      event = AuthEvents.Default,
      step,
      choice,
      funnel,
      ...rest
    }: {
      event?: string;
      step: string;
      funnel: Funnels;
      choice?: string;
      target?: string | null;
    }) => {
      const funnelType = (funnel && targetTrackingMap[funnel]) || 'generic';
      track(event, {
        dashboard_event: 'choiceClick',
        step,
        funnel_type: funnelType,
        project: authProject,
        choice,
        device_category: deviceType,
        ...rest,
      });
    },
    [deviceType],
  );
};

export const usePageView = ({
  step,
  funnel,
}: {
  step: string;
  funnel?: Funnels;
}) => {
  const isMobile = useMediaQuery(`(max-width : ${MEDIA_QUERIES.TABLET})`);
  const deviceType = isMobile ? 'mobile' : 'web';
  useEffect(() => {
    const funnelType = (funnel && targetTrackingMap[funnel]) || 'generic';

    track('product_onboardingwizard', {
      dashboard_event: 'pageView',
      funnel_type: funnelType,
      project: authProject,
      device_category: deviceType,
      step,
    });
  }, [step, funnel, deviceType]);
};
