import axios, { AxiosPromise } from 'axios';

import { getCsrfToken, setCsrfToken } from './csrfToken';
import { SignupUser, SignUpUserResponse, UserResponsePayload } from './types';

export const signupUser = (
  requestData: SignupUser,
): Promise<{
  payload: SignUpUserResponse;
  error: [];
}> => {
  return axios
    .post('/authentication/sign-up', requestData, {
      headers: {
        'Accept-Language': window.appLang,
      },
    })
    .then(({ data }) => {
      setCsrfToken(data.payload.token);
      return data;
    })
    .catch(err => {
      throw err;
    });
};

type Error = { description: string; key: string };
type LoginResponse = Promise<{
  errors: Error[];
  payload: {
    token: string;
    firstLogin: boolean;
    twoFaRequired?: boolean;
    twoFaType?: string;
    redirectUrl: string;
  };
}>;
export const loginUser = (requestData: {
  email: string;
  password: string | undefined;
  remember_me: boolean;
  'g-recaptcha-response': string;
}): LoginResponse => {
  return axios.post('/authentication/login', requestData).then(({ data }) => {
    setCsrfToken(data.payload.token);
    return data;
  });
};

export const getSignupProduct = (): Promise<string | null> => {
  return axios
    .get('/workspace/flags')
    .then(({ data }) => data.payload.signupProduct);
};

export const forgotPassword = (requestData: {
  emailaddress: string;
  'g-recaptcha-response': string;
}): AxiosPromise<{
  errors: string[];
  payload: { message: string; profile_id: string };
}> => {
  return axios
    .post('/react-forgot-password', {
      ...requestData,
      _csrf: getCsrfToken(),
    })
    .then(({ data }) => data);
};

export const resend2fa = (): Promise<void> => {
  return axios.post('/authentication/2fa/retry').then(({ data }) => data);
};

export function login2faUser(requestData: {
  check_code: string;
}): Promise<void> {
  return axios
    .post('/authentication/2fa/check', requestData)
    .then(({ data }) => data);
}
export const getCreatePassword = (requestData: {
  profile_id: string | undefined;
  hash: string;
}): Promise<{
  errors: string[];
  payload: { emailaddress: string; profile_id: string; hash: string };
}> => {
  return axios
    .get('/react-create-password', { params: requestData })
    .then(({ data }) => data);
};

export const postCreatePassword = (requestData: {
  password: string;
  profile_id: string;
  hash: string;
}): AxiosPromise<{
  errors: string[];
  payload: { message: string };
}> => {
  return axios
    .post('/react-create-password', {
      ...requestData,
      _csrf: getCsrfToken(),
    })
    .then(({ data }) => data);
};

// Get data associated with the active workspace
export const fetchUserData = (): Promise<UserResponsePayload> =>
  axios.get('/user').then(resp => resp.data);
