import { useEffect } from 'react';

import { FormattedMessage } from 'react-intl';

import {
  RecoveryTitle as Title,
  SubTitle,
} from '../../components/shared/styles';
import VerificationCodeForm from '../../components/TwoFactorAuth/VerificationCodeForm';
import { page } from '../../helpers/analytics';
import { traceCounter } from '../../helpers/metrics';
import { Analytics } from '../../helpers/types';

const TwoFactorAuth = () => {
  useEffect(() => {
    page(Analytics.LOGIN_2FA, { path: window.location.href });
    traceCounter('login_2fa_auth2_page_loads');
  }, []);

  function translationSlugForTwoFaType(slug: string) {
    if (window.location.hash) {
      let twoFaType = window.location.hash.slice(1);
      if (['email', 'sms'].includes(twoFaType)) {
        return slug + '.' + twoFaType;
      }
    }
    return slug;
  }

  return (
    <>
      <Title>
        <FormattedMessage
          id="auth.2fa.title"
          defaultMessage="Enter verification code"
        />
      </Title>
      <SubTitle>
        <FormattedMessage
          id={translationSlugForTwoFaType('auth.2fa.subtitle')}
          defaultMessage="We just sent a 6-digit confirmation code to your number"
        />
      </SubTitle>
      <VerificationCodeForm />
    </>
  );
};

export default TwoFactorAuth;
