import queryString from 'query-string';
import { useHistory } from 'react-router-dom';

import { logger } from '../errorTracking';

export type TargetTypes = typeof signupTargets[number];

export default function useGetTarget(): TargetTypes | undefined {
  const history = useHistory();
  const { target } = queryString.parse(history.location!.search) as {
    target: TargetTypes;
  };

  if (target && !signupTargets.includes(target)) {
    logger.message({
      message: '[Auth] Possible XSS attempt using non-existing target',
      level: 'warning',
      tags: { security: 'xss', target: target },
    });
    document.location.search = '';
  }

  return target;
}

const signupTargets = [
  //used at Marketing pages
  'inbox',
  'api',
  'wa-api',
  'wa-inbox',
  'gbm',
  //not used yet
  'sip',
  'sms_api',
  'chatbot',
  'verify',
  'abandoned-cart',
  'flow-builder',
  'campaign-builder',
  'instagram',
  //existing on backend but not used
  'whatsapp',
  'solutions',
  'unified_onboarding',
];
