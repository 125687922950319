import { Button } from '@messagebird/plume';
import { FormattedMessage } from 'react-intl';

import { RecoveryTitle as Title, Text } from '../../shared/styles';

const buttonStyle = {
  display: 'block',
  width: '100%',
};

const Success: React.FC = () => (
  <>
    <Title>
      <FormattedMessage
        id="auth.forget-password.success.title"
        defaultMessage="Success!"
      />
    </Title>
    <Text>
      <FormattedMessage
        id="auth.forget-password.success.subtitle"
        defaultMessage="You have successfully changed your password."
      />
    </Text>
    <Button as="a" href="/" style={buttonStyle}>
      <FormattedMessage
        id="auth.forget-password.success.button"
        defaultMessage="Go to my dashboard"
      />
    </Button>
  </>
);

export default Success;
