import { useEffect, useState } from 'react';
// useMediaQuery(query: string) => boolean
// takes a query string in params eg: (max-width: 768px)
export const useMediaQuery = (mediaQueryString: string) => {
  const [queryMatch, setQueryMatch] = useState<boolean>(
    window.matchMedia(mediaQueryString).matches,
  );

  useEffect(() => {
    const mediaQueryList = window.matchMedia(mediaQueryString);
    const setMediaMatchHandler = () => setQueryMatch(mediaQueryList.matches);

    if (mediaQueryList.matches !== queryMatch) {
      setMediaMatchHandler();
    }

    mediaQueryList.addListener(setMediaMatchHandler);

    return () => mediaQueryList.removeListener(setMediaMatchHandler);
  }, [mediaQueryString, queryMatch]);

  return queryMatch;
};
