import styled from 'styled-components';

import { MEDIA_QUERIES } from '../../../helpers/mediaQueries';
import { useMediaQuery } from '../../../hooks/useMediaQuery';
import HeaderMessage from './HeaderMessage';
import Logo from './icons/Logo';

const Header: React.FC<{ pathname: string }> = ({ pathname }) => {
  const isMobile = useMediaQuery(`(max-width : ${MEDIA_QUERIES.TABLET})`);
  return (
    <HeaderContainer>
      <Logo />
      {!isMobile && <HeaderMessage pathname={pathname} />}
    </HeaderContainer>
  );
};

export default Header;

const HeaderContainer = styled.header`
  display: flex;
  padding: var(--plm-dt-size-spacing-5) var(--plm-dt-size-spacing-6);
  color: var(--plm-dt-color-gray-80);
  align-items: center;
  justify-content: space-between;
  font-size: var(--plm-dt-size-text-sm);

  @media only screen and (max-width: ${MEDIA_QUERIES.TABLET}) {
    justify-content: center;
  }
`;
