import { IntlShape } from 'react-intl';
import * as yup from 'yup';

export function createSchema(intl: IntlShape) {
  return yup.object().shape({
    password: yup
      .string()
      .required(
        intl.formatMessage({
          id: 'errors.required',
          defaultMessage: 'This field is required.',
        }),
      )
      .min(
        8,
        intl.formatMessage(
          {
            id: 'auth.signup.validation.min',
            defaultMessage: '{field} must be at least {min} characters',
          },
          {
            field: intl.formatMessage({
              id: 'auth.signup.password',
              defaultMessage: 'Password',
            }),
            min: 8,
          },
        ),
      )
      .max(
        1000,
        intl.formatMessage(
          {
            id: 'auth.signup.validation.max',
            defaultMessage: '{field} must be no more than {max} characters',
          },
          {
            field: intl.formatMessage({
              id: 'auth.signup.password',
              defaultMessage: 'Password',
            }),
            max: 1000,
          },
        ),
      ),
    confirmPassword: yup
      .string()
      .required(
        intl.formatMessage({
          id: 'errors.required',
          defaultMessage: 'This field is required.',
        }),
      )
      .oneOf(
        [yup.ref('password'), null],
        intl.formatMessage({
          id: 'errors.passwords-match',
          defaultMessage: 'Passwords must match.',
        }),
      ),
  });
}
