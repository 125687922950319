import axios, { AxiosRequestConfig } from 'axios';
// import { apiMetricInterceptor } from 'metrics/apiErrorInterceptor';

import { getCsrfToken } from './csrfToken';

// Automatically set the correct headers on each axios request so we don't have to do it on each request
function setAuthHeaders(requestConfig: AxiosRequestConfig) {
  if (requestConfig.headers.Authorization) {
    return requestConfig;
  }

  requestConfig.headers['X-CSRF-Token'] = getCsrfToken();
  requestConfig.headers['Request-From'] = 'React';

  if (requestConfig.headers['Content-Type'] !== 'multipart/form-data') {
    requestConfig.headers['X-Requested-With'] = 'XMLHttpRequest';
  }

  return requestConfig;
}

if (axios.interceptors) {
  axios.interceptors.request.use(setAuthHeaders);
  //   axios.interceptors.response.use(
  //     (r) => r,
  //     (r: AxiosError | any) => {
  //       if (r.isAxiosError) {
  //         apiMetricInterceptor(r);
  //       }
  //       return Promise.reject(r);
  //     },
  //   );
}
