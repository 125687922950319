import { useRef, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Checkbox, Input, Link } from '@messagebird/plume';
import { FadeAnimation } from 'components/shared/FadeAnimation';
import ReCAPTCHA from 'react-google-recaptcha';
import { Controller, useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link as RouterLink } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import * as yup from 'yup';

import { loginUser } from '../../helpers/api';
import { isRobotLogin } from '../../helpers/handleRobots';
import { traceCounter } from '../../helpers/metrics';
import useLoginSuccessCallback from '../../hooks/useLoginSuccessCallback';
import { ActionRow, Container, Form } from '../shared/styles';

interface FormData {
  email: string;
  password: string;
  remember_me: boolean;
  'g-recaptcha-response': string;
}
const visibleFields: Array<keyof FormData> = ['password', 'email'];
const defaultError = {
  id: 'activation.instagram.channel-creation.InternalError',
  defaultMessage: 'Oops. Something went wrong. Please try again.',
};

const LoginForm: React.FC<{
  onCancel: () => void;
}> = ({ onCancel }) => {
  const [isSubmitting, setSubmitting] = useState<boolean>(false);
  const intl = useIntl();
  const successCallback = useLoginSuccessCallback();
  const history = useHistory();
  const captchaRef = useRef<ReCAPTCHA>(null);

  const LoginSchema = yup.object().shape({
    email: yup
      .string()
      .email(
        intl.formatMessage(
          {
            id: 'auth.signup.validation.email',
            defaultMessage: '{field} must be a valid email',
          },
          {
            field: intl.formatMessage({
              id: 'auth.signup.email',
              defaultMessage: 'Work email',
            }),
          },
        ),
      )
      .required(
        intl.formatMessage({
          id: 'errors.required',
          defaultMessage: 'This field is required.',
        }),
      ),
    password: yup.string().when('oauth_id', {
      is: (v: string) => !v,
      then: s =>
        s.required(
          intl.formatMessage({
            id: 'errors.required',
            defaultMessage: 'This field is required.',
          }),
        ),
    }),
  });

  const {
    handleSubmit,
    setValue,
    getValues,
    setError,
    watch,
    control,
    formState: { isValid },
  } = useForm<FormData>({
    resolver: yupResolver(LoginSchema),
    mode: 'onChange',
  });
  watch();
  const { email } = getValues();

  const onLogin = handleSubmit(async (data: FormData) => {
    setSubmitting(true);
    try {
      const { payload } = await loginUser({
        email: data.email,
        password: data.password,
        remember_me: data.remember_me,
        'g-recaptcha-response': data['g-recaptcha-response'],
      });
      traceCounter('login_auth2_success');
      if (payload.twoFaRequired) {
        if (payload.twoFaType) {
          history.push(`/login/2fa#` + payload.twoFaType);
        } else {
          history.push(`/login/2fa`);
        }
      } else {
        successCallback({
          firstLogin: payload.firstLogin,
          redirectUrl: payload.redirectUrl,
        });
      }
    } catch (exception: any) {
      const errors = exception?.response?.data?.errors;
      const firstError = errors && errors[0]?.description;
      const message = firstError ?? intl.formatMessage(defaultError);
      visibleFields.forEach(field => setError(field, { message }));
      traceCounter('login_auth2_errors');
      setSubmitting(false);
    }
  });

  const onSubmit = async () => {
    const hash = captchaRef?.current?.getValue() ?? '';
    // if there is a captcha - add it to a form field and login than
    if (hash || process.env.REACT_APP_DASH_ENV === 'development') {
      setValue('g-recaptcha-response', hash);
      onLogin();
      return;
    }
    // login without captcha for cypress tests emails
    if (await isRobotLogin(email)) {
      onLogin();
      return;
    }
    // if there is no captcha, execute captcha, captcha will submit the form
    captchaRef?.current?.execute();
  };

  return (
    <Form
      onKeyDown={async e => {
        if (e.key === 'Enter') {
          e.preventDefault();
          if (isValid && !isSubmitting) {
            await onSubmit();
          }
        }
      }}
    >
      <FadeAnimation>
        <Container>
          <Controller
            name="email"
            control={control}
            rules={{
              required: true,
            }}
            render={({ field, fieldState: { error, isTouched } }) => (
              <Input
                label={intl.formatMessage({
                  id: 'auth.signup.email',
                  defaultMessage: 'Work email',
                })}
                type="email"
                id="email"
                autoComplete="email"
                placeholder={intl.formatMessage({
                  id: 'auth.signup.work-email-placeholder',
                  defaultMessage: 'Your work email',
                })}
                errorMessage={error?.message}
                validationState={
                  (isTouched && error?.message) || (isTouched && !field.value)
                    ? 'invalid'
                    : isTouched
                    ? 'valid'
                    : undefined
                }
                autoFocus
                {...field}
              />
            )}
          />
        </Container>

        <Container>
          <Controller
            name="password"
            control={control}
            render={({ field, fieldState: { isTouched, error } }) => (
              <Input
                togglePasswordButtonText="Reveal"
                label={intl.formatMessage({
                  id: 'auth.signup.password',
                  defaultMessage: 'Password',
                })}
                type="password"
                id="password"
                placeholder={intl.formatMessage({
                  id: 'auth.password.placeholder',
                  defaultMessage: 'Your password',
                })}
                isRequired={true}
                errorMessage={error?.message}
                autoComplete="current-password"
                validationState={
                  (isTouched && error?.message) || (isTouched && !field.value)
                    ? 'invalid'
                    : isTouched
                    ? 'valid'
                    : undefined
                }
                {...field}
              />
            )}
          />
        </Container>

        <Container
          isCheckbox
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Controller
            name="remember_me"
            control={control}
            render={({ field: { onChange, onBlur, value, name, ref } }) => (
              <Checkbox
                onBlur={onBlur}
                onChange={onChange}
                isSelected={value}
                name={name}
                ref={ref}
                value={String(value)}
              >
                <FormattedMessage
                  id="auth.login.rememberMe"
                  defaultMessage="Remember me"
                />
              </Checkbox>
            )}
          />
          <Link as={RouterLink} size="sm" weight="bold" to="/forgot-password">
            <FormattedMessage
              id="auth.login.forgot"
              defaultMessage="I forgot my password"
            />
          </Link>
        </Container>

        <ActionRow>
          <Button variant="secondary" type="button" onClick={onCancel}>
            <FormattedMessage id="auth.back" defaultMessage="Back" />
          </Button>

          <Button
            variant="primary"
            data-cy={'logIn'}
            type="button"
            onClick={onSubmit}
            isDisabled={!isValid || isSubmitting}
          >
            {isSubmitting ? (
              <>
                <FormattedMessage
                  id="auth.login.loggingin"
                  defaultMessage="Logging in"
                />
                ...
              </>
            ) : (
              <FormattedMessage id="auth.login.login" defaultMessage="Log in" />
            )}
          </Button>
        </ActionRow>
      </FadeAnimation>
      <ReCAPTCHA
        ref={captchaRef}
        sitekey={process.env.REACT_APP_RECAPTCHA_KEY!}
        size="invisible"
        badge="bottomleft"
        onChange={(hash: string | null) => {
          /** Add recaptcha response to the form fields and resubmit */
          setValue('g-recaptcha-response', hash || '');
          if (hash && isValid && !isSubmitting) {
            onLogin();
            captchaRef?.current?.reset();
          }
        }}
      />
    </Form>
  );
};

export default LoginForm;
