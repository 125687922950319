import { logger } from '../errorTracking';

const getSHA256Hash = async (str: string) => {
  const msgUint8 = new TextEncoder().encode(str); // encode as (utf-8) Uint8Array
  const hashBuffer = await crypto.subtle.digest('SHA-256', msgUint8); // hash the message
  const hashArray = Array.from(new Uint8Array(hashBuffer)); // convert buffer to byte array
  const hashHex = hashArray.map(b => b.toString(16).padStart(2, '0')).join(''); // convert bytes to hex string
  return hashHex;
};

export const isRobotLogin = async (emailAddress: string): Promise<boolean> => {
  if (window?.rbtMls) {
    // robots can't do captcha ¯\_(ツ)_/¯
    try {
      const robotEmails: string[] = JSON.parse(
        // it's base64 encoded
        atob(window?.rbtMls),
      );
      const email =
        emailAddress.indexOf('+') > 0
          ? emailAddress.replace(
              emailAddress.substring(
                emailAddress.indexOf('+'),
                emailAddress.indexOf('@'),
              ),
              '',
            )
          : emailAddress;
      if (robotEmails.includes(await getSHA256Hash(email))) {
        return true;
      }
    } catch (err: any) {
      logger.message({ message: 'RobotEmails validation failed', extras: err });
    }
    return false;
  }

  return false;
};
