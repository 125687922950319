// https://gist.github.com/strinkaus-gd/68f8b5cf7bebab50930357b2eab58fb9
import { useEffect, useState } from 'react';

export function createStore<T>(state: T) {
  let storeState: T = Object.assign({}, state);
  const storeSetters = new Set<(state: T) => void>();

  const setStoreState = (state: Partial<T>) => {
    storeState = Object.assign({}, storeState, state);
    storeSetters.forEach(setter => setter(storeState));
  };

  function useStore(): [T, typeof setStoreState] {
    const [state, setState] = useState(storeState);
    useEffect(() => {
      () => storeSetters.delete(setState); //eslint-disable-line @typescript-eslint/no-unused-expressions
    }, []);
    storeSetters.add(setState);
    return [state, setStoreState];
  }

  return useStore;
}
