import { useEffect, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { Input } from '@messagebird/plume';
import queryString from 'query-string';
import { Controller, useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import Success from '../../components/CreatePassword/Success';
import {
  ActionRow,
  Container,
  Form,
  FullWidthButton,
  RecoveryTitle as Title,
  TextStyle,
} from '../../components/shared/styles';
import { logger } from '../../errorTracking';
import { page } from '../../helpers/analytics';
import { getCreatePassword, postCreatePassword } from '../../helpers/api';
import { Analytics } from '../../helpers/types';
import { Status } from '../ForgotPassword';
import { createSchema } from './schema';

interface FormData {
  password: string;
  confirmPassword: string;
  profile_id: string;
  hash: string;
}

type QueryParams = {
  profile_id: string;
  hash: string;
};

export default function CreatePassword() {
  const intl = useIntl();
  const [email, setEmail] = useState<string>('');
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [status, setStatus] = useState<Status>(Status.IDLE);
  const [errorServer, setErrorServer] = useState<{
    id: string;
    defaultMessage: string;
  }>();
  const history = useHistory();
  const { profile_id, hash } = queryString.parse(
    history.location!.search,
  ) as QueryParams;

  const CreatePasswordSchema = createSchema(intl);

  const {
    handleSubmit,
    watch,
    setValue,
    control,
    formState: { isValid },
  } = useForm<FormData>({
    resolver: yupResolver(CreatePasswordSchema),
    mode: 'onChange',
  });

  watch();

  useEffect(() => {
    page(Analytics.CREATE_PASSWORD, { path: window.location.href });
  }, []);

  useEffect(() => {
    const fetchEmail = async () => {
      try {
        const response = await getCreatePassword({ profile_id, hash });
        if (response) {
          setEmail(response.payload.emailaddress);
          setValue('profile_id', response.payload.profile_id);
          setValue('hash', response.payload.hash);
        }
      } catch (error: any) {
        if (error?.response?.status !== 422) {
          logger.message({
            message: `[AUTH] Create password: ${error}`,
            level: 'warning',
          });
        }
        setTimeout(() => {
          history.push({
            pathname: `/forgot-password`,
            search: '?invalidHash=true',
          });
        }, 3000);
      }
    };

    fetchEmail();
  }, [hash, history, profile_id, setValue]);

  const onSubmit = handleSubmit(async (formData: FormData) => {
    setIsSubmitting(true);

    try {
      await postCreatePassword({
        password: formData.password,
        profile_id: formData.profile_id,
        hash: formData.hash,
      });
      setStatus(Status.COMPLETED);
      setIsSubmitting(false);
    } catch (e: any) {
      const { status, data } = e;
      if (status !== 422) {
        logger.message({
          message: `[AUTH] Create password - status !== 422: ${data}`,
          level: 'warning',
        });
      }
      if (!data || !data?.errors) {
        setErrorServer({
          id: 'auth.errorBoundary.something-wrong',
          defaultMessage: 'Oops! Something went wrong.',
        });
      } else {
        setErrorServer({
          id: `auth.login.api.${data?.errors[0]?.key}`,
          defaultMessage: data?.errors[0]?.description,
        });
      }
      setIsSubmitting(false);
    }
  });

  if (status === Status.COMPLETED) {
    return <Success />;
  }

  return (
    <>
      <Title>
        <FormattedMessage
          id="auth.create-password.title"
          defaultMessage="Change your password"
        />
      </Title>

      <Subtitle>
        <FormattedMessage
          id="auth.create-password.subtitle"
          defaultMessage="Type the new password you want to use for {email}"
          values={{ email: <strong>{email}</strong> }}
        />
      </Subtitle>

      <Form onSubmit={onSubmit} aria-label="form">
        <Container>
          <Controller
            name="password"
            control={control}
            rules={{
              required: true,
            }}
            render={({ field, fieldState: { error, isTouched } }) => (
              <PasswordInputContainer>
                <Input
                  {...field}
                  togglePasswordButtonText={intl.formatMessage({
                    id: 'auth.password-reveal',
                    defaultMessage: 'Reveal password',
                  })}
                  label={intl.formatMessage({
                    id: 'auth.signup.new-password',
                    defaultMessage: 'New Password',
                  })}
                  type="password"
                  id="password"
                  autoComplete="new-password"
                  placeholder={intl.formatMessage({
                    id: 'auth.password.placeholder',
                    defaultMessage: 'Your password',
                  })}
                  isRequired={true}
                  errorMessage={
                    isTouched && (error?.message || errorServer?.defaultMessage)
                  }
                  validationState={
                    isTouched && (error?.message || errorServer)
                      ? 'invalid'
                      : isTouched
                      ? 'valid'
                      : undefined
                  }
                />
              </PasswordInputContainer>
            )}
          />
          <Controller
            name="confirmPassword"
            control={control}
            rules={{
              required: true,
            }}
            render={({ field, fieldState: { error, isTouched } }) => (
              <Input
                {...field}
                togglePasswordButtonText={intl.formatMessage({
                  id: 'auth.password-confirm-reveal',
                  defaultMessage: 'Confirm reveal password',
                })}
                label={intl.formatMessage({
                  id: 'auth.signup.confirm-password',
                  defaultMessage: 'Confirm new password',
                })}
                type="password"
                id="confirmPassword"
                placeholder={intl.formatMessage({
                  id: 'auth.password.placeholder',
                  defaultMessage: 'Your password',
                })}
                isRequired={true}
                errorMessage={error?.message || errorServer?.defaultMessage}
                validationState={
                  error?.message || errorServer
                    ? 'invalid'
                    : isTouched
                    ? 'valid'
                    : undefined
                }
              />
            )}
          />
        </Container>

        <ActionRow>
          <FullWidthButton
            variant="primary"
            type="submit"
            data-testid="createPassword"
            isDisabled={
              !isValid || !!errorServer?.defaultMessage || isSubmitting
            }
          >
            <FormattedMessage
              id="auth.create-password.change"
              defaultMessage="Change Password"
            />
          </FullWidthButton>
        </ActionRow>
      </Form>
    </>
  );
}

const Subtitle = styled.p`
  ${TextStyle}
`;

const PasswordInputContainer = styled.div`
  margin-bottom: var(--plm-dt-size-spacing-4);
`;
