import React from 'react';

import { FadeAnimation } from 'components/shared/FadeAnimation';
import { MEDIA_QUERIES } from 'helpers/mediaQueries';
import { FormattedMessage } from 'react-intl';
import { useLocation } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { useMediaQuery } from '../../hooks/useMediaQuery';
import HeaderMessage from '../Layout/Header/HeaderMessage';
import FacebookIcon from '../shared/SocialButtons/assets/Facebook';
import GmailIcon from '../shared/SocialButtons/assets/Gmail';
import KeyholeIcon from '../shared/SocialButtons/assets/Keyhole';
import {
  Box,
  Line,
  FacebookSocialButton,
  FullWidthButton as Button,
  GoogleSocialButton,
  SSOLoginButton,
} from '../shared/styles';

type Props = {
  onLoginWithEmail: () => void;
};

const LoginActions: React.FC<Props> = ({ onLoginWithEmail }) => {
  const location = useLocation();
  const isMobile = useMediaQuery(`(max-width : ${MEDIA_QUERIES.TABLET})`);

  return (
    <FadeAnimation>
      <Box>
        <Button
          variant="primary"
          size="large"
          data-cy={'logInEmail'}
          onClick={onLoginWithEmail}
        >
          <FormattedMessage
            id="auth.login.loginwithemail"
            defaultMessage="Log in with email"
          />
        </Button>

        {!window?.appBranding?.isWhitelabel && (
          <React.Fragment>
            <Line>
              <FormattedMessage id="auth.or" defaultMessage="OR" />
            </Line>
            <SocialButtons isMobile={isMobile}>
              <GoogleSocialButton href="/login/auth-login?authclient=google">
                <GmailIcon />
                <FormattedMessage
                  id="auth.login.loginWithGoogle"
                  defaultMessage="Log in with Google"
                />
              </GoogleSocialButton>
              <FacebookSocialButton href="/login/auth-login?authclient=facebook">
                <FacebookIcon />
                <FormattedMessage
                  id="auth.login.loginWithFacebook"
                  defaultMessage="Log in with Facebook"
                />
              </FacebookSocialButton>
              <SSOLoginButton href="/login/sso-login">
                <KeyholeIcon />
                <FormattedMessage
                  id="auth.login.with.sso"
                  defaultMessage="Log in with SSO"
                />
              </SSOLoginButton>
            </SocialButtons>
          </React.Fragment>
        )}

        {isMobile && (
          <HeaderMessage pathname={location.pathname} isMobile={isMobile} />
        )}
      </Box>
    </FadeAnimation>
  );
};

export default LoginActions;

const SocialButtons = styled.div<{ isMobile: boolean }>`
  flex-direction: column;

  ${p =>
    p.isMobile &&
    css`
      button:last-of-type {
        margin-bottom: 32px;
      }

      > a {
        margin-bottom: var(--plm-dt-size-spacing-6);
      }
    `}
`;
