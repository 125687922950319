import { PlumeProvider } from '@messagebird/plume';
import * as tokens from '@messagebird/plume-design-tokens';
import { IntlProvider } from 'react-intl';
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from 'react-router-dom';

import Layout from './components/Layout/Layout';
import { LoadingRemover } from './components/LoadingRemover';
import '@messagebird/plume-design-tokens/dist/css/variables.css';
import '@messagebird/plume/dist/plume.css';
import { initializeSentryClient, useSentryHub } from './errorTracking';
import { withInstrumentationContext } from './helpers/analyticsNew';
import { getAppLanguage, langToMessages, Languages } from './helpers/intl';
import useAuthenticatedUser from './hooks/useAuthenticatedUser';
import CreatePassword from './pages/CreatePassword';
import ForgotPassword from './pages/ForgotPassword';
import Login from './pages/Login';
import Signup from './pages/Signup';
import TwoFactorAuth from './pages/TwoFactorAuth';

initializeSentryClient();

// opting to use recapture.net for all captcha requests due to the great firewall of china
// https://developers.google.com/recaptcha/docs/faq#can-i-use-recaptcha-globally
// @ts-ignore
window.recaptchaOptions = {
  useRecaptchaNet: true,
};

function App() {
  const lang: Languages = getAppLanguage();
  useSentryHub();

  useAuthenticatedUser();

  return (
    <IntlProvider
      messages={langToMessages[lang]}
      locale={lang}
      defaultLocale="en"
    >
      <Router basename={window.appLang || 'en'}>
        <PlumeProvider value={tokens}>
          <LoadingRemover />
          <Layout>
            <Switch>
              <Route path="/login/2fa" component={TwoFactorAuth} />
              <Route path="/login" component={Login} />
              <Route path="/signup" component={Signup} />
              <Route path="/sign-up" component={Signup} />
              <Route path="/forgot-password" component={ForgotPassword} />
              <Route path="/create-password" component={CreatePassword} />
              <Route exact path="/">
                <Redirect to="/login" />
              </Route>
            </Switch>
          </Layout>
        </PlumeProvider>
      </Router>
    </IntlProvider>
  );
}

export default withInstrumentationContext(App);
