import { useEffect } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Text } from '@messagebird/plume';
import queryString from 'query-string';
import { useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import * as yup from 'yup';

import { Title } from '../../components/shared/styles';
import { page } from '../../helpers/analytics';
import {
  Analytics,
  Funnels,
  FormData,
  targetTrackingMap,
} from '../../helpers/types';
import useGetTarget from '../../hooks/useGetTarget';

type Params = {
  tier: string | undefined;
};

const SignUp = () => {
  const target = useGetTarget() as Funnels;
  const type = (target && targetTrackingMap[target]) || 'generic';
  const intl = useIntl();
  const history = useHistory();
  const { tier } = queryString.parse(history.location!.search) as Params;
  const SignupSchema = yup.object().shape({
    email: yup
      .string()
      .email(
        intl.formatMessage(
          {
            id: 'auth.signup.validation.email',
            defaultMessage: '{field} must be a valid email',
          },
          {
            field: intl.formatMessage({
              id: 'auth.signup.email',
              defaultMessage: 'Work email',
            }),
          },
        ),
      )
      .required(
        intl.formatMessage({
          id: 'errors.required',
          defaultMessage: 'This field is required.',
        }),
      ),
    password: yup
      .string()
      .min(
        6,
        intl.formatMessage(
          {
            id: 'auth.signup.validation.min',
            defaultMessage: '{field} must be at least {min} characters',
          },
          {
            field: intl.formatMessage({
              id: 'auth.signup.password',
              defaultMessage: 'Password',
            }),
            min: 8,
          },
        ),
      )
      .when('oauth_id', {
        is: (v: string) => !v,
        then: s =>
          s.required(
            intl.formatMessage(
              {
                id: 'auth.signup.validation.min',
                defaultMessage: '{field} must be at least {min} characters',
              },
              {
                field: intl.formatMessage({
                  id: 'auth.signup.password',
                  defaultMessage: 'Password',
                }),
                min: 8,
              },
            ),
          ),
      }),
    coupon: yup.string().min(
      6,
      intl.formatMessage(
        {
          id: 'auth.signup.coupon.validation',
          defaultMessage:
            'Your coupon must be at least {couponlength} characters',
        },
        { couponlength: 6 },
      ),
    ),
    agree: yup.boolean().equals([true]),
    serverError: yup.string(),
  });

  const { watch } = useForm<FormData>({
    resolver: yupResolver(SignupSchema),
    mode: 'onChange',
  });

  watch();

  useEffect(() => {
    page(Analytics.SIGNUP, { type });
  }, [type]);

  useEffect(() => {
    if (!!tier) localStorage.setItem('inboxPlan', tier);
  }, [tier]);

  return (
    <>
      <Title>
        <FormattedMessage
          id="auth.email.createyourAccount"
          defaultMessage="Create your account"
        />
      </Title>
      <Text as="p">
        <Button
          onPress={function noRefCheck() {
            window.open('https://app.messagebird.com/auth/sign-up');
          }}
          size="medium"
          style={{
            display: 'block',
            width: '100%',
          }}
          variant="primary"
        >
          Sign up here
        </Button>
      </Text>
    </>
  );
};

export default SignUp;
