import { Link } from '@messagebird/plume';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import { getInboxUrl } from '../../helpers/inboxAuth';

function LoginWithInbox() {
  const link = `${getInboxUrl()}/login`;
  return (
    <TextLink>
      <FormattedMessage
        id="auth.login.inbox"
        defaultMessage="Looking for Inbox? <a>Log in here.</a>"
        values={{
          a: (msg: string) => (
            <Link
              size="sm"
              target="_blank"
              rel="noopener noreferrer"
              href={link}
            >
              {msg}
            </Link>
          ),
        }}
      />
    </TextLink>
  );
}

export default LoginWithInbox;

const TextLink = styled.p`
  text-align: center;
  color: var(--plm-dt-color-gray-100);
  font-size: var(--plm-dt-size-text-sm);
`;
