import { useEffect, useState } from 'react';

import { Notification } from '@messagebird/plume';
import { useQueryParam } from 'hooks/useQueryParam';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import LoginActions from '../../components/Login/LoginActions';
import LoginForm from '../../components/Login/LoginForm';
import { Title } from '../../components/shared/styles';
import { page } from '../../helpers/analytics';
import { traceCounter } from '../../helpers/metrics';
import { Analytics } from '../../helpers/types';

const Login = () => {
  const [showButtons, setShowButtons] = useState<boolean>(true);
  const [showNotification, setShowNotification] = useState<boolean>(true);

  useEffect(() => {
    page(Analytics.LOGIN_FORM, { path: window.location.href });
    traceCounter('login_page_auth2_loads');
  }, []);

  const hasInboxReferrer = useQueryParam().get('source') === 'inbox';

  const onCancel = () => {
    setShowButtons(true);
  };

  const onLoginWithEmail = () => {
    setShowButtons(!showButtons);
  };

  return (
    <>
      <Title>
        <FormattedMessage id="auth.login.title" defaultMessage="Welcome back" />
      </Title>
      {hasInboxReferrer && showNotification && (
        <StyledNotification
          variant="info"
          onClose={() => setShowNotification(false)}
          closeText={'Dismiss notification'}
          isClosable
        >
          <FormattedMessage
            id="auth.login.inbox.notification"
            defaultMessage="You are an Inbox manager. Please log in on this page instead."
          />
        </StyledNotification>
      )}
      {showButtons ? (
        <LoginActions onLoginWithEmail={onLoginWithEmail} />
      ) : (
        <LoginForm onCancel={onCancel} />
      )}
    </>
  );
};

export default Login;

const StyledNotification = styled(Notification)`
  margin-bottom: var(--plm-dt-size-spacing-3);
`;
