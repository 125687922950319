import React, { useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Input, Notification } from '@messagebird/plume';
import queryString from 'query-string';
import ReCAPTCHA from 'react-google-recaptcha';
import { Controller, useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import * as yup from 'yup';

import CheckEmail from '../../components/ForgotPassword/CheckEmail';
import {
  RecoveryTitle as Title,
  Container,
  ActionRow,
  Form,
} from '../../components/shared/styles';
import { logger } from '../../errorTracking';
import { forgotPassword } from '../../helpers/api';

interface FormData {
  email: string;
  'g-recaptcha-response': string;
}

export enum Status {
  COMPLETED = 'COMPLETED',
  ERROR = 'ERROR',
  IDLE = 'IDLE',
}

type QueryParams = {
  invalidHash: string;
};

export default function ForgotPassword() {
  const captchaRef = React.useRef<ReCAPTCHA>(null);
  const [errorServer, setErrorServer] = React.useState<{
    id: string;
    defaultMessage: string;
  }>();
  const [isSubmitting, setSubmitting] = useState<boolean>(false);
  const [status, setStatus] = useState<Status>(Status.IDLE);
  const [hasInvalidHash, setHasInvalidHash] = useState<boolean>(false);
  const intl = useIntl();
  const history = useHistory();

  const { invalidHash } = queryString.parse(
    history.location!.search,
  ) as QueryParams;

  React.useEffect(() => {
    setHasInvalidHash(invalidHash === 'true');
  }, [invalidHash]);

  React.useEffect(() => {
    if (status === Status.COMPLETED) {
      setTimeout(() => {
        history.push(`/login`);
      }, 5000);
    }
  }, [status, history]);

  const ForgotPasswordSchema = yup.object().shape({
    email: yup
      .string()
      .email(
        intl.formatMessage(
          {
            id: 'auth.signup.validation.email',
            defaultMessage: '{field} must be a valid email',
          },
          {
            field: intl.formatMessage({
              id: 'auth.signup.email',
              defaultMessage: 'Work email',
            }),
          },
        ),
      )
      .required(
        intl.formatMessage({
          id: 'errors.required',
          defaultMessage: 'This field is required.',
        }),
      ),
  });

  const {
    handleSubmit,
    setValue,
    control,
    watch,
    formState: { isValid },
  } = useForm<FormData>({
    resolver: yupResolver(ForgotPasswordSchema),
    mode: 'onChange',
  });

  watch();

  const onForgetPassword = handleSubmit(async (formData: FormData) => {
    setSubmitting(true);
    try {
      await forgotPassword({
        emailaddress: formData.email,
        'g-recaptcha-response': formData['g-recaptcha-response'],
      });
      setStatus(Status.COMPLETED);
      setSubmitting(false);
    } catch (exception: any) {
      if (!exception.response) {
        setStatus(Status.ERROR);
        logger.message({
          message: `[Auth] ${exception}`,
        });

        setErrorServer({
          id: 'dashboard.errorBoundary.title',
          defaultMessage: 'Oops! Something went wrong.',
        });
        setSubmitting(false);
        return;
      }
      const { response } = exception;
      const message = response?.data?.errors[0]?.description;
      setStatus(Status.ERROR);
      if (response.status !== 422 && response.status !== 200) {
        logger.message({
          message: `[Auth]Forget password - status != 422: ${response.status} - ${response?.data?.errors}`,
        });
      }

      if (!response?.data || !response?.data?.errors) {
        setErrorServer({
          id: 'dashboard.errorBoundary.title',
          defaultMessage: 'Oops! Something went wrong.',
        });
      } else {
        setErrorServer({
          id: `auth.login.api.${response?.data?.errors[0].key}`,
          defaultMessage: message,
        });
      }
      setSubmitting(false);
    }
  });

  const onSubmit = () => {
    const hash = captchaRef?.current?.getValue() ?? '';
    // if there is a captcha - add it to a form field and login than
    if (hash || process.env.REACT_APP_DASH_ENV === 'development') {
      setValue('g-recaptcha-response', hash);
      onForgetPassword();
      return;
    }
    // if there is no captcha, execute captcha, captcha will submit the form
    captchaRef?.current?.execute();
  };

  if (status === Status.COMPLETED) {
    return <CheckEmail />;
  }

  return (
    <>
      <Title>
        <FormattedMessage
          id="auth.forget-password.title"
          defaultMessage="Recover your password"
        />
      </Title>
      <p>
        <FormattedMessage
          id="auth.forget-password.subtitle"
          defaultMessage="Fill in your email address and we’ll send you a link to reset your password."
        />
      </p>
      <Form
        onKeyDown={e => {
          if (e.key === 'Enter') {
            e.preventDefault();
            if (isValid && !isSubmitting) {
              onSubmit();
            }
          }
        }}
      >
        {hasInvalidHash && (
          <Notification
            variant="error"
            onClose={() => {
              setHasInvalidHash(false);
            }}
            closeText={'Dismiss notification'}
            isClosable
          >
            <FormattedMessage
              id="auth.login.create-password.expired"
              defaultMessage="The link to recover your password has expired. Please request a new link."
            />
          </Notification>
        )}

        <Container hasNotification isCheckbox>
          <Controller
            name="email"
            control={control}
            rules={{
              required: true,
            }}
            render={({ field, fieldState: { error, isTouched } }) => (
              <Input
                label={intl.formatMessage({
                  id: 'auth.signup.email',
                  defaultMessage: 'Work email',
                })}
                type="email"
                id="email"
                autoComplete="email"
                placeholder={intl.formatMessage({
                  id: 'auth.signup.work-email-placeholder',
                  defaultMessage: 'Work email',
                })}
                errorMessage={error?.message || errorServer?.defaultMessage}
                validationState={
                  isTouched && (error?.message || errorServer)
                    ? 'invalid'
                    : isTouched
                    ? 'valid'
                    : undefined
                }
                {...field}
              />
            )}
          />
        </Container>

        <ActionRow>
          <Button
            variant="secondary"
            type="button"
            onClick={() => {
              history.push('/login');
            }}
          >
            <FormattedMessage id="auth.back" defaultMessage="Back" />
          </Button>
          <Button
            variant="primary"
            data-cy="recoverPassword"
            data-testid="recoverPassword"
            type="button"
            onClick={onSubmit}
            isDisabled={!isValid || isSubmitting}
          >
            {isSubmitting ? (
              <>
                <FormattedMessage
                  id="auth.forget-password.title-loading"
                  defaultMessage="Recovering your password"
                />
                ...
              </>
            ) : (
              <FormattedMessage
                id="auth.forget-password.title"
                defaultMessage="Recover your password"
              />
            )}
          </Button>
        </ActionRow>

        <ReCAPTCHA
          ref={captchaRef}
          sitekey={process.env.REACT_APP_RECAPTCHA_KEY!}
          size="invisible"
          badge="bottomleft"
          onChange={(hash: string | null) => {
            /** Add recaptcha response to the form fields and resubmit */
            setValue('g-recaptcha-response', hash || '');
            if (hash && isValid && !isSubmitting) {
              onForgetPassword();
              captchaRef?.current?.reset();
            }
          }}
        />
      </Form>
    </>
  );
}
