import React from 'react';

import queryString, { ParsedUrl } from 'query-string';

type redirectArguments = {
  firstLogin: boolean;
  redirectUrl?: string;
};

export type loginSuccessCallback = (args: redirectArguments) => void;

function onboardingDestination(): ParsedUrl {
  return {
    url: `/${window.appLang}/user/index`,
    query: {
      firstLogin: '1',
      pip: 'universal_solutions',
    },
  };
}

function useLoginSuccessCallback(): loginSuccessCallback {
  return React.useCallback(async ({ firstLogin, redirectUrl }) => {
    let destination: ParsedUrl = {
      url: redirectUrl || `/${window.appLang}/user/index`,
      query: queryString.parse(window.location.search),
    };
    if (firstLogin) {
      destination = onboardingDestination();
    }
    const redirectUrlString = queryString.stringifyUrl(destination);
    // full page refresh to allow php to load all of necessary base html for logged in view
    window.location.href = redirectUrlString;
  }, []);
}

export default useLoginSuccessCallback;
