import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import { MEDIA_QUERIES } from '../../../helpers/mediaQueries';
import AbnIcon from './icons/Abn';
import DeliverooIcon from './icons/Deliveroo';
import FacebookIcon from './icons/Facebook';
import UberIcon from './icons/Uber';
import WhatsappIcon from './icons/Whatsapp';

const Companies = [
  AbnIcon,
  DeliverooIcon,
  FacebookIcon,
  UberIcon,
  WhatsappIcon,
];

const Footer: React.FC = () => {
  return (
    <FooterWrapper>
      <Text>
        <FormattedMessage
          id="auth.signup.trusted"
          defaultMessage="TRUSTED BY THE WORLD’S SMARTEST COMPANIES"
        />
      </Text>
      <ImageRow>
        {Companies.map((company, index) => (
          <Image key={index}>{company()}</Image>
        ))}
      </ImageRow>
    </FooterWrapper>
  );
};

export default Footer;

const ImageRow = styled.ul`
  display: flex;
  flex-direction: column;
  align-items: center;
  list-style: none;
  margin: 0;
  padding: 0;
  @media only screen and (min-width: ${MEDIA_QUERIES.TABLET}) {
    margin-top: var(--plm-dt-size-spacing-4);
    flex-direction: row;
    justify-content: space-between;
  }
`;

const Image = styled.li`
  margin: 0 var(--plm-dt-size-spacing-5);
`;

const Text = styled.p`
  text-transform: uppercase;
  font-size: 10px;
  font-weight: 600;
  letter-spacing: 1px;
  color: var(--plm-dt-color-gray-60);
  margin: 0;
`;

const FooterWrapper = styled.footer`
  margin-top: auto;
  margin-bottom: var(--plm-dt-size-spacing-10);
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
`;
