import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  twoFactorResent: {
    id: 'auth.login.twoFactorResent',
    defaultMessage: 'We have sent another code to your phone',
  },
  loginError: {
    id: 'auth.login.error',
    defaultMessage:
      "We couldn't log you in. Make sure all the information is correct.",
  },
  validationMin: {
    id: 'auth.signup.validation.min',
    defaultMessage: '{field} must be at least {min} characters',
  },
  validationMax: {
    id: 'auth.signup.validation.max',
    defaultMessage: '{field} must be no more than {max} characters',
  },
  fieldRequired: {
    id: 'errors.required',
    defaultMessage: 'This field is required.',
  },
  code: {
    id: 'auth.2fa.code',
    defaultMessage: 'Code',
  },
  enterCode: {
    id: 'auth.2fa.enter.code',
    defaultMessage: 'Enter your code you received',
  },
  logIn: { id: 'auth.login.login', defaultMessage: 'Log in' },
});
