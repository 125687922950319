export function MessageBirdLogo() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 31 25" height="24">
      <path
        fill="#0089FF"
        fillRule="evenodd"
        d="M26.8 6.8a.7.7 0 01-.7-.6c0-.4.3-.7.7-.7.4 0 .7.3.7.7 0 .3-.3.6-.7.6m0-2.4a3.6 3.6 0 00-3 1.5l-4.3 6a.9.9 0 01-.8.4.9.9 0 01-.9-.8l.2-.5 3.7-5.5a3.6 3.6 0 00-3-5.5H0v3.5h16c0 1-.8 1.8-1.7 1.8H0a8.7 8.7 0 00.7 3.5h11.8c0 1-.8 1.8-1.8 1.8h-9A9 9 0 009 14h4.4c.5 0 .9.4.9.9s-.4.9-1 .9H9l-6 8.8h10.8c5 0 9.2-3.2 10.7-7.6l2-6a11 11 0 013.4-5 3.6 3.6 0 00-3.1-1.7"
      />
    </svg>
  );
}
